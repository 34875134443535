import React from "react";
import "./ButtonLink.css";

function ButtonLink(props) {
	return (
		<button
			title={props.title}
			className="buttonLink"
			onClick={(e) => props.onClick(e)}
		>
			{props.children}
		</button>
	);
}

export default ButtonLink;
