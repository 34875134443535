import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
	fetchMattersList,
	setDeleted,
	exportMatter,
	setEditMatterRedirectLocation,
	openBulkMatterPermissionsDialog,
} from "../actions/matterActions";
import { setResizeElement } from "../actions/globalScreenSizeActions";
import { setGlobalSearchMatterIDs } from "../actions/globalSearchbarActions";

import { setSubheaderText } from "../actions/navigateActions";
import { RowCheckbox } from "./RowCheckbox";
import { ActionsTableCell } from "./Tables/InteractiveTableCell";
import CustomizableTable from "./Tables/CustomizableTable";
import InteractiveTableCell from "./Tables/InteractiveTableCell";
import { ButtonWithRouter } from "./ButtonWithIcon";
import { DropDownButtonWithRouter } from "./DropDownButton";
import {
	shortDateFormatOffset,
	filmCreditsFlexHelper,
} from "../utilities/utilities";

const tableUniqueID = "/matters";

class MattersList extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			showDeleted: false,
		};
		this.toggleShowDeleted = this.toggleShowDeleted.bind(this);
		this.setVisibleIDs = this.setVisibleIDs.bind(this);
		this.makeActionButtonsComponent =
			this.makeActionButtonsComponent.bind(this);
		this.makeCustomizableTableLeftSideComponent =
			this.makeCustomizableTableLeftSideComponent.bind(this);
		const makeMatterThreeDotMenuItems = (row) => {
			const postEditLocation = this.props.postEditLocation || "/matters";
			let menu = [
				{
					text: "Edit Matter Info",
					href: "/matters/" + row.original.id + "/edit",
					selectCallback: () =>
						this.props.setEditMatterRedirectLocation(
							postEditLocation
						),
				},
			];
			if (this.props.hasPermissionsAccess) {
				menu.push({
					text: "Edit Matter Permissions",
					href: "/matters/" + row.original.id + "/edit/permissions",
					selectCallback: () =>
						this.props.setEditMatterRedirectLocation(
							postEditLocation
						),
				});
			}
			menu = menu.concat([
				{
					text: "Export Matter",
					onSelect: () => this.props.exportMatter(row.original.id),
				},
				{
					text: row.original.isDeleted
						? "Restore Matter"
						: "Delete Matter",

					onSelect: () =>
						this.props.setDeleted(
							!row.original.isDeleted,
							row.original.id,
							this.props.onlyShowMattersForClientID
						),
				},
			]);
			return menu;
		};
		this.columnConfig = [
			{ Header: "ID", accessor: "id", width: 40 },
			{
				Header: "Matter Name",
				accessor: "name",
				Cell: (row) => (
					<InteractiveTableCell
						menuItems={makeMatterThreeDotMenuItems(row)}
					>
						<Link to={"/matters/" + row.original.id + "/docs/"}>
							{row.value}
						</Link>
					</InteractiveTableCell>
				),
				wildcard: true,
			},
			{
				Header: "Client Name",
				accessor: "clientName",
				Cell: (row) => row.value,
				wildcard: true,
			},
			{
				Header: "Custom Matter ID",
				accessor: "customMatterID",
				wildcard: true,
			},
			{ Header: "Matter Type", accessor: "matterType", wildcard: true },
			{
				Header: "Office/Branch",
				accessor: "officeBranch",
				wildcard: true,
			},
			{
				Header: "Opened Date",
				accessor: "dateOpened",
				Cell: (row) => row.value && shortDateFormatOffset(row.value),
				filterValueMethod: (x) => shortDateFormatOffset(x.dateOpened),
			},
			{
				Header: "Closed Date",
				accessor: "dateClosed",
				Cell: (row) => row.value && shortDateFormatOffset(row.value),
				filterValueMethod: (x) => shortDateFormatOffset(x.dateClosed),
			},
			{
				Header: "Tags",
				accessor: "tags",
				Cell: (row) =>
					row.original.tags
						? row.value.map((x) => (
								<div className="tagPill" key={x.tagName}>
									{x.tagName}
								</div>
							))
						: "",
				filterValueMethod: (x) =>
					x.tags.map((t) => t.tagName).join(", "),
			},
			{
				Header: "Assigned Staff",
				accessor: "assignedStaff",
				Cell: (row) =>
					row.value
						? row.value.map((x) => x.displayName).join(", ")
						: "",
				filterValueMethod: (x) =>
					(x.assignedStaff || [])
						.map((s) => s.displayName)
						.join(", "),
			},
			{
				Header: "",
				accessor: "actions",
				width: 32,
				sortable: false,
				filterable: false,
				Cell: (row) => (
					<ActionsTableCell
						row={row}
						isDeleted={row.original.isDeleted}
						trashClickHandler={() => {
							this.props.setDeleted(
								!row.original.isDeleted,
								row.original.id,
								this.props.onlyShowMattersForClientID
							);
						}}
					/>
				),
			},
		];
		this.filterMap = {
			All: () => true,
			Open: (row) => {
				return (
					!row.dateClosed ||
					(row.dateClosed &&
						new Date(row.dateClosed).getTime() >
							new Date().getTime())
				);
			},
			Closed: (row) => {
				return (
					row.dateClosed &&
					new Date(row.dateClosed).getTime() <= new Date().getTime()
				);
			},
			My: (row) => {
				return (
					row.assignedStaff &&
					row.assignedStaff.length &&
					row.assignedStaff.reduce(
						(acc, cur) => acc || cur.sub === this.props.cognitoSub,
						false
					)
				);
			},
		};
	}
	componentDidMount() {
		this.props.setResizeElement("mattersListTableContainer");
	}
	componentWillUnmount() {
		this.setVisibleIDs({ all: [], filtered: null });
	}

	toggleShowDeleted() {
		this.setState({ showDeleted: !this.state.showDeleted });
	}

	setVisibleIDs(ids) {
		this.props.setGlobalSearchMatterIDs(ids);
	}

	makeActionButtonsComponent() {
		let actionsMenuItems = [
			{
				text: filmCreditsFlexHelper(
					"Include Deleted Items",
					<RowCheckbox checked={this.state.showDeleted} />
				),
				onSelect: this.toggleShowDeleted,
			},
		];
		if (this.props.hasPermissionsAccess) {
			actionsMenuItems.push({
				text: "Bulk Matter Permissions",

				/*
					It would be great to disable/enable this button based on if there are at least
					one visible item, but accessing the visibleMatters from the globalSearchbar state
					causes an infinite loop.  The <CusomizableTable /> re-renders, which sets
					the globalSearchbarState.allVisibleMatterIDsOnScreen, which causes this
					<MattersList /> component to re-render and so on forever

				disabled: this.props.numVisibleMatters < 1,
				disabledHoverText:
					"At least one matter must be visible in order to apply bulk permissions",*/
				onSelect: () =>
					this.props.openBulkMatterPermissionsDialog({
						allMatters: this.props.mattersListData,
						clientName: this.props.clientName,
					}),
			});
		}

		return (
			<div style={{ display: "flex", gap: 8 }}>
				<ButtonWithRouter
					redirect={
						this.props.newButtonRedirectLocation || "/matters/new"
					}
					buttonText="New Matter"
					disabled={!this.props.canCreateMatter}
					iconName="briefcase"
					disabledHoverText="You do not have permissions to create new matters"
				/>
				<DropDownButtonWithRouter
					title="Actions"
					iconName="gavel"
					pullRight
					menuItems={actionsMenuItems}
				/>
			</div>
		);
	}

	makeCustomizableTableLeftSideComponent() {
		return this.props.showMatterFilterTabs
			? null
			: this.props.matterFilterTabsReplacementContent;
	}

	render() {
		return (
			<div className="fullHeightFlex noDefaultMargin">
				<CustomizableTable
					{...this.props}
					resolveData={(allData) => {
						// this is a built-in react-table prop that is called whenever the component re-renders
						const ids = allData.map((m) => m.id);
						this.setVisibleIDs({ all: ids });
						return allData;
					}}
					actionButtons={this.makeActionButtonsComponent()}
					leftSideFilterSpace={this.makeCustomizableTableLeftSideComponent()}
					className="fullWidth"
					loading={this.props.loading}
					tabsFilterMap={
						this.props.showMatterFilterTabs ? this.filterMap : null
					}
					defaultTabFilterValue={"Open"}
					leftSideIcon="briefcase"
					data={this.props.mattersListData.filter(
						(row) => this.state.showDeleted || !row.isDeleted
					)}
					autoResize
					columns={this.columnConfig}
					noResultsText={"No Matters in this view"}
					onFilteredChange={(filteredColumns) => {
						if (!filteredColumns || !filteredColumns.length) {
							this.setVisibleIDs({ filtered: null });
						}
					}}
					filteringCompleteCallback={(filteredData) => {
						const ids = filteredData.map((m) => m.id);
						this.setVisibleIDs({ filtered: ids });
					}}
					tableUniqueID={tableUniqueID}
				/>
			</div>
		);
	}
}
MattersList.propTypes = {
	fetchMattersList: PropTypes.func.isRequired,
	setSubheaderText: PropTypes.func.isRequired,
	mattersListData: PropTypes.array.isRequired,
	title: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
	title: state.router.location.pathname,
	clientDetails: state.client.clientDetails,
	mattersListData: state.client.mattersList,
	myUserID: state.user.userID,
	loading: state.request.loading,
	cognitoSub: state.user.cognitoUser.attributes.sub,
	hasPermissionsAccess: state.user.hasPermissionsAccess,
	canCreateMatter:
		state.permissionFeatures.allowedFeatures.CREATE_MATTER_ALLOWED,
});
export default connect(mapStateToProps, {
	setSubheaderText,
	fetchMattersList,
	setResizeElement,
	setDeleted,
	exportMatter,
	setEditMatterRedirectLocation,
	setGlobalSearchMatterIDs,
	openBulkMatterPermissionsDialog,
})(MattersList);
