import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import matchSorter from "match-sorter";
import "react-table/react-table.css";
import "./SortableTable.css";
import {
	setTableSort,
	setTableColumnWidths,
} from "../../actions/tableSortingActions";

import {
	goToPage,
	setFilteredCount,
} from "../../actions/globalPaginationActions";

class UnconnectedSortableTable extends Component {
	constructor(props, context) {
		super(props, context);

		this.mixinColumnVisibility = this.mixinColumnVisibility.bind(this);
	}
	mixinColumnVisibility() {
		return this.props.columns.map((item) => {
			var newColumnConfig = {
				...item,
				headerClassName: "leftAlignedTableHeader",
			};

			if (this.props.columnVisibility) {
				newColumnConfig.show =
					item.accessor === "selected" ||
					this.props.columnVisibility[item.accessor] !== false;
			}
			return newColumnConfig;
		});
	}
	render() {
		var propsWithResizeStuff = { ...this.props };
		if (this.props.autoResize) {
			propsWithResizeStuff.getTheadProps = () => ({
				style: {
					paddingRight: this.props.scrollbarWidth + "px",
				},
			});
			propsWithResizeStuff.getTheadFilterProps = () => ({
				style: {
					paddingRight: this.props.scrollbarWidth + "px",
				},
			});
		}

		const columnsWithVisibility = this.mixinColumnVisibility();

		return (
			<div
				className={
					"SortableTable -striped -highlight " +
					(this.props.className || "")
				}
			>
				<ReactTable
					{...propsWithResizeStuff}
					data={this.props.loading ? [] : this.props.data}
					columns={columnsWithVisibility}
					loading={this.props.loading || false}
					loadingText={""}
					defaultPageSize={this.props.defaultPageSize || 50}
					page={
						this.props.uncontrolledPagination
							? undefined
							: this.props.currentPage - 1
					}
					defaultSorted={
						this.props.userOverriddenSort &&
						this.props.userOverriddenSort.sort
							? this.props.userOverriddenSort.sort
							: this.props.defaultSorted
					}
					onResizedChange={(newResized, event) => {
						this.props.setTableColumnWidths(
							this.props.tableUniqueID,
							newResized
						);
					}}
					onSortedChange={(newSorted, column, additive) => {
						this.props.setTableSort(
							this.props.tableUniqueID,
							newSorted,
							additive
						);
						this.props.onSortedChange &&
							this.props.onSortedChange(
								newSorted,
								column,
								additive
							);
					}}
					resized={
						this.props.userOverriddenSort
							? this.props.userOverriddenSort.resizedColumns
							: []
					}
					pageSize={
						this.props.defaultPageSize ||
						Math.min(50, this.props.data.length)
					}
					className="-striped -highlight"
					NoDataComponent={() =>
						this.props.loading ? null : (
							<h3 className="rt-noData">
								{this.props.noResultsText}
							</h3>
						)
					}
				/>
			</div>
		);
	}
}
function escapeRegex(string) {
	return string.replace(/[-/\\^$+?.()|[\]{}]/g, "\\$&");
}
const SortableTable = connect(
	(state, ownProps) => ({
		scrollbarWidth: state.global.scrollbarWidth,
		userOverriddenSort: state.tableSorting[ownProps.tableUniqueID],
		currentPage: state.pagination.currentPage,
	}),
	{ setTableSort, setTableColumnWidths }
)(UnconnectedSortableTable);

class UnconnectedFilterableTable extends Component {
	componentWillUnmount() {
		this.props.setFilteredCount(-1);
	}
	render() {
		return (
			<SortableTable
				{...this.props}
				onFilteredChange={(filtered) => {
					this.props.goToPage(1);
					document.querySelector(".rt-tbody").scrollTop = 0;
					this.props.onFilteredChange &&
						this.props.onFilteredChange(filtered);
					if (filtered.length === 0) {
						this.props.setFilteredCount(-1);
					}
				}}
				columns={this.props.columns.map((c) => {
					return {
						...c,
						//dont show the "Filter..."" placeholder for narrow columns
						Placeholder:
							c.Placeholder === ""
								? ""
								: c.Placeholder ||
									(c.width < 51 ? "" : "Filter..."),

						filterMethod: (filter, rows) => {
							var matchSort;
							var sortConfig = {
								threshold: matchSorter.rankings.CONTAINS,
							};
							if (c.filterValueMethod) {
								//pass a custom function to grab the values for filtering
								if (c.wildcard) {
									let re = new RegExp(
										escapeRegex(filter.value).replace(
											/\*/g,
											".*"
										),
										"i"
									);
									//filterValueMethod returns the value to match against
									matchSort = rows.filter((v) =>
										c.filterValueMethod(v).match(re)
									);
								} else {
									matchSort = matchSorter(
										rows,
										filter.value,
										{
											...sortConfig,
											keys: [c.filterValueMethod],
										}
									);
								}
							} else {
								if (c.wildcard) {
									let re = new RegExp(
										escapeRegex(filter.value).replace(
											/\*/g,
											".*"
										),
										"i"
									);
									matchSort = rows.filter(
										//check for null and cast to string to ensure match works
										(v) =>
											v[filter.id] &&
											("" + v[filter.id]).match(re)
									);
								} else {
									matchSort = matchSorter(
										rows,
										filter.value,
										{
											...sortConfig,
											keys: [filter.id],
										}
									);
								}
							}
							if (this.props.filteringCompleteCallback) {
								this.props.filteringCompleteCallback(
									matchSort,
									filter?.id,
									this.props.activeFilters
								);
							}
							this.props.setFilteredCount(matchSort.length);
							return matchSort;
						},
						filterAll: true,
					};
				})}
				filterable
			/>
		);
	}
}
const FilterableTable = connect(null, { goToPage, setFilteredCount })(
	UnconnectedFilterableTable
);

export default SortableTable;
export { FilterableTable };
