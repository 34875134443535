import React, { Component } from "react";
import { CircularProgress, Divider, Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import "./AsyncSwitch.css";

const SwitchWithStyles = withStyles(() => ({
	root: {
		width: 28,
		height: 16,
		padding: 0,
		display: "flex",
	},
	disabled: {
		// needs to be here to apply the opacity to the switchBase
	},
	switchBase: {
		padding: 2,
		color: "grey",
		"&$checked": {
			transform: "translateX(12px)",
			color: "white",
			"& + $track": {
				opacity: 1,
				backgroundColor: "var(--lw-blue)",
				borderColor: "var(--lw-blue)",
			},
			"&$disabled": {
				opacity: 0.6,
				"& + $track": {
					opacity: 1,
					backgroundColor: "var(--lw-light-blue-b)",
					borderColor: "var(--lw-light-blue-b)",
				},
			},
		},
		"&$disabled": {
			opacity: 0.6,
			"& + $track": {
				opacity: 1,
				backgroundColor: "grey",
				borderColor: "grey",
			},
		},
	},
	thumb: {
		width: 12,
		height: 12,
		boxShadow: "none",
	},
	track: {
		border: `1px solid grey`,
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor: "white",
	},
	checked: {},
}))(Switch);

export const SimpleSwitch = ({
	switchValue,
	handleSwitchValueChange,
	label,
	disabled,
	isValueUpdating,
	valueUpdatedSuccess,
	valueUpdatedFailed,
	disabledHoverText,
	smallerFontForLabel,
}) => {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
			}}
			title={disabledHoverText}
		>
			{smallerFontForLabel ? <h5>{label}</h5> : <h4>{label}</h4>}
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					gap: 5,
				}}
			>
				<div>
					{isValueUpdating ? <CircularProgress size={15} /> : ""}
					{valueUpdatedSuccess ? (
						<FontAwesomeIcon color="green" icon="check" size="1x" />
					) : (
						""
					)}
					{valueUpdatedFailed ? (
						<FontAwesomeIcon color="red" icon="times" size="1x" />
					) : (
						""
					)}
				</div>
				<SwitchWithStyles
					checked={switchValue}
					onChange={handleSwitchValueChange}
					name={label}
					disabled={disabled || isValueUpdating}
				/>
			</div>
		</div>
	);
};
class AsyncSwitch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			canSubmit: false,
			switchValue: false,
			switchUpdating: false,
			updateSuccess: false,
			updateFailed: false,
			loading: true,
		};
		this.handleStateUpdate = this.handleStateUpdate.bind(this);
	}

	static getDerivedStateFromProps(props, state) {
		if (state.loading && !props.loading) {
			const newState = {
				loading: false,
				switchValue: !!props.switchValue,
			};
			return newState;
		} else {
			return null;
		}
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleStateUpdate(value, success, failed) {
		setTimeout(() => {
			if (this._isMounted) {
				this.setState(
					{
						switchValue: value,
						switchUpdating: false,
						updateSuccess: success,
						updateFailed: failed,
					},
					() => {
						if (this._isMounted) {
							setTimeout(() => {
								this.setState({
									updateSuccess: false,
									updateFailed: false,
								});
							}, 1000);
						}
					}
				);
			}
		}, 1000);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.updateRequestCount !== this.props.updateRequestCount) {
			const updateSuccessfull =
				!!this.props.switchValue === this.state.switchValue;

			const updateFailed =
				!!this.props.switchValue !== this.state.switchValue;

			this.handleStateUpdate(
				!!this.props.switchValue,
				updateSuccessfull,
				updateFailed
			);
		}
	}

	render() {
		return (
			<>
				<SimpleSwitch
					label={this.props.label}
					switchValue={this.state.switchValue}
					isValueUpdating={this.state.switchUpdating}
					valueUpdatedSuccess={this.state.updateSuccess}
					valueUpdatedFailed={this.state.updateFailed}
					disabledHoverText={this.props.disabledHoverText}
					handleSwitchValueChange={() => {
						let newSwitchValue = !this.state.switchValue;
						this.setState(
							{
								switchValue: newSwitchValue,
								switchUpdating: true,
							},
							() => {
								this.props.updateSetting(newSwitchValue);
							}
						);
					}}
					labelPlacement="start"
					disabled={this.props.disabled}
				/>
				<Divider />
			</>
		);
	}
}
AsyncSwitch.propTypes = {
	label: PropTypes.string.isRequired,
	switchValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	disabled: PropTypes.bool.isRequired,
	updateSetting: PropTypes.func.isRequired,
	updateRequestCount: PropTypes.number.isRequired,
};

export default AsyncSwitch;
