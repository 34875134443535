import React from "react";

import { MenuItem, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import "./DropDownButton.css";
import { faUpload, faFolderPlus } from "@fortawesome/free-solid-svg-icons";

class DropDownButton extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.redirect = this.redirect.bind(this);
	}
	redirect(eventKey) {
		// history comes from routers withRouter method
		const { history } = this.props;

		history.push(eventKey);
	}
	menuItem() {
		return (
			<MenuItem onSelect={this.props.firsthandler} eventKey="{i}">
				Matter
			</MenuItem>
		);
	}

	render() {
		var propsMixin = {};
		if (this.props.onOpen) {
			propsMixin.onClick = this.props.onOpen;
		}
		return (
			<Dropdown
				{...propsMixin}
				dropup={this.props.dropup ? true : false}
				className={
					"DropDownButton " +
					(this.props.className || "RegularDropDown")
				}
				id={"dropdown-custom-" + Math.floor(Math.random() * 100000)}
				pullRight={this.props.pullRight}
			>
				<Dropdown.Toggle>
					{this.props.noIcon ? null : this.props.iconName ===
					  "upload" ? (
						<FontAwesomeIcon icon={faUpload} size="1x" />
					) : (
						<FontAwesomeIcon
							icon={this.props.iconName || "briefcase"}
							size="1x"
						/>
					)}
					<span>{this.props.title}</span>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{this.props.menuItems.map((item, i) => {
						if (item) {
							if (item.divider) {
								return (
									<MenuItem
										key={"DIVID_" + Math.random()}
										divider
									/>
								);
							}
							if (item.href) {
								return (
									<MenuItem
										onSelect={(eventKey) => {
											item.selectCallback &&
												item.selectCallback();
											this.redirect(eventKey);
										}}
										eventKey={item.href}
										disabled={item.disabled}
										key={i}
									>
										{item.iconName ? (
											<div
												title={
													item.disabled
														? item.disabledHoverText
														: null
												}
												style={{
													paddingTop: 5,
													paddingBottom: 5,
												}}
											>
												<FontAwesomeIcon
													icon={item.iconName}
													style={{
														color: item.iconColor,
														marginRight: 12,
													}}
												/>

												<span>{item.text}</span>
											</div>
										) : (
											item.text
										)}
									</MenuItem>
								);
							} else if (item.type === "multi-file-upload") {
								return (
									<MenuItem
										onSelect={() => {
											var input = document.getElementById(
												`dropdown-upload-${i}`
											);
											input.click();
										}}
										eventKey={i}
										key={i}
										disabled={item.disabled}
									>
										{item.iconName ? (
											<div
												title={
													item.disabled
														? item.disabledHoverText
														: null
												}
												style={{
													paddingTop: 5,
													paddingBottom: 5,
												}}
											>
												<FontAwesomeIcon
													icon={item.iconName}
													style={{
														color: item.iconColor,
														marginRight: 16,
													}}
												/>

												<span>{item.text}</span>
												<input
													id={`dropdown-upload-${i}`}
													disabled={item.disabled}
													type="file"
													multiple="multiple"
													style={{ display: "none" }}
													onChange={(event) => {
														const files =
															event.target.files;
														if (
															item.fileListChangeHandler &&
															files.length
														) {
															item.fileListChangeHandler(
																files,
																() =>
																	console.log(
																		"file upload callback"
																	)
															);
														}
													}}
													accept={
														item.uploadAccept || "*"
													}
													onClick={(event) => {
														event.target.value =
															null;
														event.stopPropagation();
													}}
												/>
											</div>
										) : (
											item.text
										)}
									</MenuItem>
								);
							} else if (item.type === "single-upload") {
								return (
									<MenuItem
										onSelect={() => {
											var input = document.getElementById(
												`dropdown-upload-${i}`
											);
											input.click();
										}}
										eventKey={i}
										key={i}
										disabled={item.disabled}
									>
										{item.iconName ? (
											<div
												title={
													item.disabled
														? item.disabledHoverText
														: null
												}
												style={{
													paddingTop: 5,
													paddingBottom: 5,
												}}
											>
												<FontAwesomeIcon
													icon={item.iconName}
													style={{
														color: item.iconColor,
														marginRight: 16,
													}}
												/>

												<span>{item.text}</span>
												<input
													id={`dropdown-upload-${i}`}
													disabled={item.disabled}
													type="file"
													style={{ display: "none" }}
													onChange={(event) => {
														const files =
															event.target.files;
														if (
															item.fileListChangeHandler &&
															files.length
														) {
															item.fileListChangeHandler(
																files,
																() =>
																	console.log(
																		"file upload callback"
																	)
															);
														}
													}}
													accept={
														item.uploadAccept || "*"
													}
													onClick={(event) => {
														event.target.value =
															null;
														event.stopPropagation();
													}}
												/>
											</div>
										) : (
											item.text
										)}
									</MenuItem>
								);
							} else if (item.type === "multi-folder-upload") {
								return (
									<MenuItem
										onSelect={() => {
											var input = document.getElementById(
												`dropdown-upload-${i}`
											);
											input.click();
										}}
										eventKey={i}
										key={i}
										disabled={item.disabled}
									>
										{item.iconName ? (
											<div
												style={{
													paddingTop: 5,
													paddingBottom: 5,
												}}
											>
												<FontAwesomeIcon
													icon={faFolderPlus}
													style={{
														color: item.iconColor,
														marginRight: 12,
													}}
												/>

												<span>{item.text}</span>
												<input
													id={`dropdown-upload-${i}`}
													disabled={item.disabled}
													type="file"
													webkitdirectory="true"
													// eslint-disable-next-line
													mozdirectory="true"
													// eslint-disable-next-line
													directory="true"
													style={{ display: "none" }}
													onChange={(event) => {
														const files =
															event.target.files;
														if (
															item.fileListChangeHandler &&
															files.length
														) {
															item.fileListChangeHandler(
																files,
																() =>
																	console.log(
																		"folder upload callback"
																	)
															);
														}
													}}
													accept={
														item.uploadAccept || "*"
													}
													onClick={(event) => {
														event.target.value =
															null;
														event.stopPropagation();
													}}
												/>
											</div>
										) : (
											item.text
										)}
									</MenuItem>
								);
							} else {
								return (
									<MenuItem
										onSelect={() => {
											item.onSelect();
										}}
										eventKey={i}
										key={i}
										disabled={item.disabled}
									>
										{item.iconName ? (
											<div
												title={
													item.disabled
														? item.disabledHoverText
														: null
												}
												style={{
													paddingTop: 5,
													paddingBottom: 5,
												}}
											>
												<FontAwesomeIcon
													icon={item.iconName}
													style={{
														color: item.iconColor,
														marginRight: 12,
													}}
												/>

												<span>{item.text}</span>
											</div>
										) : (
											<div
												title={
													item.disabled
														? item.disabledHoverText
														: null
												}
												style={{
													paddingTop: 5,
													paddingBottom: 5,
												}}
											>
												<span>{item.text}</span>
											</div>
										)}
									</MenuItem>
								);
							}
						} else {
							return <MenuItem key={Math.random()} />;
						}
					})}
				</Dropdown.Menu>
			</Dropdown>
		);
	}
}

const DropDownButtonWithRouter = withRouter(DropDownButton);
export { DropDownButton, DropDownButtonWithRouter };
