import React from "react";

import { Link } from "react-router-dom";

import { BreadcrumbsWithMatterName } from "./Breadcrumbs";

import ButtonLink from "./ButtonLink";

import {
	iconMap,
	mediumDateFormat,
	pluralize,
	pluralizeWithNum,
	getDocumentLockText,
	mediumDateFormatMinusTimezone,
	numberWithCommas,
} from "../utilities/utilities";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./SearchResults.css";

const TagPill = (props) => {
	return props.text ? (
		<span title={props.text} className="searchTagPill">
			{props.text}
		</span>
	) : null;
};
let maxShownTags = 3;
const LimitShownTags = ({ tagIDs, documentID, documentTagNames }) => {
	if (!tagIDs.length) {
		return null;
	}
	let tagNames = tagIDs.map((tagID) => documentTagNames[tagID]);
	let shownTags = tagNames.slice(0, maxShownTags);
	let hiddenTags = tagNames.slice(maxShownTags);
	let hiddenTagsText = hiddenTags.join("\n");
	return (
		<div className="searchTagTypeWrapper">
			<span className="MainFontBold">Tags:</span>
			{shownTags.map((tagName, i) => (
				<TagPill
					tagIndex={i}
					key={"tag-" + documentID + "-" + tagIDs[i]}
					text={tagName}
				/>
			))}
			{hiddenTagsText && (
				<span className="searchTagPill" title={hiddenTagsText}>
					{`and ${hiddenTags.length} more `}
					<FontAwesomeIcon icon="info-circle" />
				</span>
			)}
		</div>
	);
};
const DocumentTagsTypes = (props) => {
	const typeID = props.documentTypeID;
	const statusID = props.documentStatusID;
	const tagIDs = props.documentTagIDs;
	return (
		<div style={{ display: "flex", flexDirection: "column" }}>
			{typeID ? (
				<div className="searchTagTypeWrapper">
					<span className="MainFontBold">Type:</span>
					<TagPill text={props.documentTypeNames[typeID]} />
				</div>
			) : null}
			{statusID ? (
				<div className="searchTagTypeWrapper">
					<span className="MainFontBold">Status:</span>
					<TagPill text={props.documentStatusNames[statusID]} />
				</div>
			) : null}
			<LimitShownTags
				tagIDs={tagIDs}
				documentID={props.documentID}
				documentTagNames={props.documentTagNames}
			/>
		</div>
	);
};
const DocumentLockStatus = (props) => {
	/*
	lockStatus:
		0 - 
		1 - Currently in use by Steve
		2 - Currently checked out to Steve
		3 - Currently checked out and in use by Steve
	*/
	return props.lockStatus ? (
		<div style={{ display: "flex" }}>
			<div>
				{getDocumentLockText(
					props.lockStatus,
					props.userNames[props.lockedBy]
				)}
			</div>
			{
				//checked out
				props.lockStatus > 1 && (
					<div
						style={{
							textAlign: "center",
							color: "var(--lw-blue)",
							marginLeft: 4,
						}}
					>
						<FontAwesomeIcon icon="share-square" />
					</div>
				)
			}
			{
				// in use
				props.lockStatus % 2 === 1 && (
					<div
						style={{
							textAlign: "center",
							color: "orange",
							marginLeft: 4,
						}}
					>
						<FontAwesomeIcon icon="user" />
					</div>
				)
			}
		</div>
	) : null;
};
const DocumentResult = (props) => {
	const { icon, color } = iconMap({
		original: { filename: props.filename, type: "File" },
	});

	return (
		<div style={{ marginTop: 36 }}>
			<div style={{ fontSize: 18 }}>
				<div style={{ display: "flex" }}>
					<FontAwesomeIcon
						icon={icon}
						style={{ color, marginRight: 8, marginTop: 2 }}
					/>
					<Link to={`/docs/${props.id}`}>
						{props.documentHighlight &&
						props.documentHighlight.documentNameSearchable ? (
							<span
								dangerouslySetInnerHTML={{
									__html: props.documentHighlight
										.documentNameSearchable[0],
								}}
							/>
						) : (
							props.filename
						)}
					</Link>
					{/*<div>({props.score})</div>*/}
					{props.isFavorite && (
						<div style={{ marginLeft: 8 }}>
							<FontAwesomeIcon
								style={{ color: "var(--lw-gold)" }}
								icon={
									props.isFavorite ? "star" : ["far", "star"]
								}
								size="1x"
							/>
						</div>
					)}
					{props.source.isDeleted && (
						<div style={{ marginLeft: 8 }}>
							<FontAwesomeIcon icon="trash" size="1x" />
						</div>
					)}
				</div>
			</div>
			<DocumentTagsTypes {...props} />
			<DocumentLockStatus {...props} />
			<span>
				{props.innerHits.count ? (
					<span>
						Results in{" "}
						{props.innerHits.count +
							pluralize(
								" version",
								numberWithCommas(props.innerHits.count)
							)}
						.{" "}
					</span>
				) : null}
				<span>
					Last modified:{" "}
					{mediumDateFormat(
						props.source.modifiedDate
							? parseInt(props.source.modifiedDate, 10)
							: props.lastModified
					)}{" "}
					{props.userNames && props.documentModifiedBy
						? "by " + props.userNames[props.documentModifiedBy]
						: ""}
				</span>
			</span>
			<BreadcrumbsWithMatterName {...props} />
			<div>
				<span className="MainFontBold">Document ID:</span>
				<span> {props.id}</span>
			</div>
			{props.innerHits.count &&
			props.innerHits.highlights &&
			props.innerHits.highlights[0] ? (
				<div>
					{props.innerHits.highlights[0].content ? (
						<div style={{ wordBreak: "break-all" }}>
							<div
								key={props.id + "_content"}
								dangerouslySetInnerHTML={{
									__html: props.innerHits.highlights[0]
										.content,
								}}
							/>{" "}
							<div>(found in document body)</div>
						</div>
					) : null}
					{props.innerHits.highlights[0].versionComment ? (
						<div style={{ wordBreak: "break-all" }}>
							<div
								key={props.id + "_content"}
								dangerouslySetInnerHTML={{
									__html: props.innerHits.highlights[0]
										.versionComment,
								}}
							/>{" "}
							<div>(found in document version comment)</div>
						</div>
					) : null}
				</div>
			) : null}
			{props.documentHighlight && props.documentHighlight.documentNote ? (
				<div style={{ wordBreak: "break-all" }}>
					{props.documentHighlight.documentNote.map(
						(snippet, dex) => (
							<div
								key={props.id + "_" + dex}
								dangerouslySetInnerHTML={{ __html: snippet }}
							/>
						)
					)}

					<div>(found in document internal note)</div>
				</div>
			) : null}
		</div>
	);
};
const MatterNoteResult = (props) => {
	const { icon, color } = iconMap({
		original: { type: "Note" },
	});
	return (
		<div style={{ marginTop: 36 }}>
			<div style={{ fontSize: 18 }}>
				<div style={{ display: "flex" }}>
					<FontAwesomeIcon
						icon={icon}
						style={{ color, marginRight: 8, marginTop: 2 }}
					/>
					<Link to={`/notes/${props.source.matterNoteID}`}>
						{props.highlight &&
						props.highlight.matterNoteNameSearchable ? (
							<span
								dangerouslySetInnerHTML={{
									__html: props.highlight
										.matterNoteNameSearchable[0],
								}}
							/>
						) : (
							props.source.matterNoteName
						)}
					</Link>
				</div>
			</div>
			<div>
				{props.modifiedOn
					? "Last modified: " +
						mediumDateFormatMinusTimezone(props.modifiedOn)
					: ""}
				{props.userNames && props.modifiedBy
					? " by " + props.userNames[props.modifiedBy]
					: ""}
			</div>
			{
				<BreadcrumbsWithMatterName
					rootName="Notes"
					rootPath="/notes/"
					{...props}
				/>
			}
			<div>
				{props.highlight && props.highlight.content ? (
					<div style={{ wordBreak: "break-all" }}>
						{props.highlight.content.map((snippet, dex) => (
							<div
								key={props.id + "_" + dex}
								dangerouslySetInnerHTML={{ __html: snippet }}
							/>
						))}
					</div>
				) : null}
			</div>
		</div>
	);
};
const EmailResult = (props) => {
	const { icon, color } = iconMap({
		original: { type: "Email" },
	});
	return (
		<div style={{ marginTop: 36 }}>
			<div style={{ fontSize: 18 }}>
				<div style={{ display: "flex" }}>
					<FontAwesomeIcon
						icon={icon}
						style={{ color, marginRight: 8, marginTop: 2 }}
					/>
					<ButtonLink
						href=""
						onClick={(e) => {
							const prefix = `${props.source.emailID}_${props.source.originalFilename}`;
							props.emailClicked(prefix);
							e.preventDefault();
							return false;
						}}
					>
						{props.highlight && props.highlight.emailSubject ? (
							<div style={{ wordBreak: "break-all" }}>
								<span
									dangerouslySetInnerHTML={{
										__html: props.highlight.emailSubject[0],
									}}
								/>
							</div>
						) : (
							props.subjectline
						)}
					</ButtonLink>
					{/*<div>({props.score})</div>*/}
				</div>
			</div>
			<BreadcrumbsWithMatterName
				rootName="Email"
				rootPath="/email/"
				{...props}
			/>
			<div>
				<span className="MainFontBold">Date:</span>
				<span> {mediumDateFormat(props.date)}</span>
			</div>
			<div>
				<span className="MainFontBold">From:</span>
				<span>
					{" "}
					{props.highlight && props.highlight.emailFromSearchable ? (
						<span
							dangerouslySetInnerHTML={{
								__html: props.highlight.emailFromSearchable[0],
							}}
						/>
					) : (
						props.emailFrom
					)}
				</span>
			</div>
			<div>
				<span className="MainFontBold">To:</span>
				<span>
					{" "}
					{props.highlight && props.highlight.emailToSearchable ? (
						<span
							dangerouslySetInnerHTML={{
								__html: props.highlight.emailToSearchable[0],
							}}
						/>
					) : (
						props.emailTo
					)}
				</span>
			</div>
			<div>
				{props.highlight && props.highlight.content ? (
					<div style={{ wordBreak: "break-all" }}>
						{props.highlight.content.map((snippet, dex) => (
							<div
								key={props.id + "_" + dex}
								dangerouslySetInnerHTML={{ __html: snippet }}
							/>
						))}
						<div>(found in email body)</div>
					</div>
				) : null}
				{props.highlight && props.highlight.emailNote ? (
					<div style={{ wordBreak: "break-all" }}>
						{props.highlight.emailNote.map((snippet, dex) => (
							<div
								key={props.id + "_" + dex}
								dangerouslySetInnerHTML={{ __html: snippet }}
							/>
						))}
						<div>(found in email short note)</div>
					</div>
				) : null}
			</div>
		</div>
	);
};
const addUpUniqueRecordsFromBucket = (buckets) => {
	return buckets.reduce((acc, cur) => {
		if (
			cur.key === "docs" ||
			cur.key === "email" ||
			cur.key === "matterNote"
		) {
			return acc + cur.doc_count;
		} else {
			return acc;
		}
	}, 0);
};

const displayFn = {
	byMatterID: (aggs, names) => {
		const singleMatterRecordCount = (matterResult) => (
			<tr key={matterResult.matterID}>
				<td
					style={{
						marginRight: 16,
						overflow: "hidden",
						textOverflow: "ellipsis",
						maxWidth: 750,
					}}
					title={matterResult.matterName}
				>
					{/*<a
						href=""
						onClick={(e) => {
							console.log(matterResult.matterID);
							onMatterNameClick(matterResult.matterID);
							e.preventDefault();
							return false;
						}}
					>*/}
					{matterResult.matterName}
					{/*</a>*/}
				</td>
				<td>{matterResult.recordCount}</td>
			</tr>
		);
		const numTopMattersToDisplay = 5;
		if (names) {
			const topMatters = aggs.buckets
				.filter((val) => names[val.key])
				.map((val) => {
					return {
						matterID: val.key,
						matterName: names[val.key],
						recordCount: addUpUniqueRecordsFromBucket(
							val.byRecordType.buckets
						),
					};
				})
				.sort((a, b) => {
					return b.recordCount - a.recordCount;
				})
				.slice(0, numTopMattersToDisplay);

			return (
				<div style={{ display: "flex" }}>
					<div>
						<table>
							<tbody>
								{topMatters
									.slice(0, numTopMattersToDisplay)
									.map(singleMatterRecordCount)}
							</tbody>
						</table>
					</div>
				</div>
			);
		} else {
			return null;
		}
	},
	/*"byRecordType": aggs => (
									<div key="by-recordType">
										{aggs.buckets
											.map(x =>
												pluralizeWithNum(
													recordTypeMap[x.key]
														.display,
													x.doc_count
												)
											)
											.join(", ")}
									</div>
								),*/
	/*uniqueDocumentIDCount: aggs => (
									<div>
										{pluralizeWithNum(
											"Document",
											aggs.value
										)}
									</div>
								),*/
	byDocumentID: () => null,
};

const AggregationResults = (props) => {
	if (props.aggregations) {
		const { aggregations, queryTook, query, totalHits, matterNames } =
			props;
		const { uniqueDocumentIDCount, byRecordType, byMatterID } =
			aggregations;

		var resultCountArray = [];

		if (uniqueDocumentIDCount && uniqueDocumentIDCount.value > 0) {
			resultCountArray.push(
				pluralizeWithNum(
					"Document",
					numberWithCommas(uniqueDocumentIDCount.value)
				)
			);
		}
		if (byRecordType && byRecordType.buckets) {
			byRecordType.buckets.forEach((x) => {
				if (x.doc_count > 0 && x.key !== "docs") {
					resultCountArray.push(
						props.recordTypeMap[x.key]
							? pluralizeWithNum(
									props.recordTypeMap[x.key].display,
									numberWithCommas(x.doc_count)
								)
							: ""
					);
				}
			});
		}

		return uniqueDocumentIDCount && byRecordType ? (
			<div>
				<div style={{ marginBottom: 16, marginTop: 16 }}>
					{totalHits ? (
						<span>
							Search found {resultCountArray.join(", ")} in{" "}
							{queryTook} seconds ({numberWithCommas(totalHits)}{" "}
							total hits)
						</span>
					) : (
						<span>
							We didn&apos;t find anything with &quot;{query}
							&quot;
						</span>
					)}
				</div>
				{totalHits ? (
					<div style={{ display: "flex" }}>
						<div
							style={{
								padding: 16,
								backgroundColor: "var(--lw-light-grey-a)",
								width: "100%",
								maxWidth: 800,
							}}
						>
							<div style={{ marginBottom: 16 }}>
								<div
									style={{
										color: "black",
									}}
									className="MainFontBold"
								>
									Top Matters
								</div>
								<div>
									(Matters with the most items matching this
									search)
								</div>
							</div>
							{byMatterID &&
								displayFn.byMatterID(
									byMatterID,
									matterNames,
									props.matterNameClickAction
								)}
						</div>
					</div>
				) : null}
			</div>
		) : (
			<div style={{ width: 600, fontSize: 14 }}>
				<div style={{ fontWeight: "bold", marginBottom: 16 }}>
					Enter search criteria above to get started.
				</div>
				LexWorkplace includes full-text search across all of your
				firm&apos;s documents. Optionally refine your search by matter,
				document type, author and more.
			</div>
		);
	} else {
		return <div> no aggs</div>;
	}
};
const SearchResults = (props) => {
	const data = props.docs.concat(props.emails).concat(props.matterNotes);

	const {
		documentTypeNames,
		documentStatusNames,
		documentTagNames,
		userNames,
	} = props;

	const componentMap = {
		docs: (pp) => (
			<DocumentResult
				{...pp}
				userNames={userNames}
				documentTypeNames={documentTypeNames}
				documentStatusNames={documentStatusNames}
				documentTagNames={documentTagNames}
			/>
		),
		documentVersion: (pp) => (
			<DocumentResult
				{...pp}
				userNames={userNames}
				documentTypeNames={documentTypeNames}
				documentStatusNames={documentStatusNames}
				documentTagNames={documentTagNames}
			/>
		),
		email: (pp) => (
			<EmailResult {...pp} emailClicked={props.emailClicked} />
		),
		matterNote: (pp) => (
			<MatterNoteResult
				{...pp}
				matterNoteClicked={props.matterNoteClicked}
				userNames={userNames}
			/>
		),
	};
	return data ? (
		data.length ? (
			<div>
				<div>
					{data
						.sort((a, b) => b.score - a.score)
						.map((d, index) => {
							return (
								<div key={`result_${index}`}>
									{componentMap[d.source.recordType](d)}
								</div>
							);
						})}
				</div>
			</div>
		) : null
	) : null;
};
export { AggregationResults, SearchResults };
