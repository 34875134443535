import React from "react";

import "./DropDownButton.css";

import { MultiColumnDropdown } from "./MultiColumnDropdown";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/*
	The `whatsNewDropdownLastHashViewed` value is saved
	in localStorage in via redux-localstorage-simple, which syncs
	with the browserLocalstorage redux state.

	To make the bell ring for all users, 
	increment `currentWhatsNewDropdownVersion`
*/

const WhatsNewDropdown = ({
	whatsNewDropdownLastHashViewed,
	updateWhatsNewDropdownLastHashViewed,
	whatsNewItems,
	whatsNewItemsHash,
}) => {
	const ringBellToIndicateNewWhatsNew =
		whatsNewDropdownLastHashViewed !== whatsNewItemsHash;

	return (
		<MultiColumnDropdown
			classes={{
				wrapper:
					"WhatsNewDropdown HollowButton " +
					(ringBellToIndicateNewWhatsNew ? "BellIsRinging" : ""),
			}}
			onOpen={() => {
				updateWhatsNewDropdownLastHashViewed(whatsNewItemsHash);
			}}
			buttonIcon={
				<FontAwesomeIcon
					icon={["far", "bell"]}
					style={{ color: "white" }}
				/>
			}
			customColumnWidth={450}
			dropdownData={{
				title: `What's new`,
				columns: [
					{
						title: "New In LexWorkplace",
						list: whatsNewItems,
					},
				],
				listItemComponentFn: ({ item, key }) => {
					return (
						<div className="WhatsNewItem" key={key}>
							<a
								href={item.url}
								target="_blank"
								rel="noopener noreferrer"
								style={{
									display: "flex",
									gap: 8,
								}}
							>
								<div
									style={{
										maxWidth: 450,
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
									title={item.text}
								>
									{item.text}
								</div>
								<div>
									<FontAwesomeIcon
										color={"var(--lw-blue)"}
										icon="arrow-up-right-from-square"
									/>
								</div>
							</a>
						</div>
					);
				},
			}}
		/>
	);
};

export default WhatsNewDropdown;
