import {
	FETCH_MATTER_DETAIL_REQUEST,
	FETCH_MATTER_DETAIL_SUCCESS,
	FETCH_MATTER_DETAIL_FAILURE,
	FETCH_MATTERS_LIST_REQUEST,
	FETCH_MATTERS_LIST_SUCCESS,
	FETCH_MATTERS_LIST_FAILURE,
	GET_EVERYTHING_FOR_NEW_EDIT_MATTER_REQUEST,
	GET_EVERYTHING_FOR_NEW_MATTER_SUCCESS,
	GET_EVERYTHING_FOR_NEW_MATTER_FAILURE,
	GET_EVERYTHING_FOR_EDIT_MATTER_SUCCESS,
	GET_EVERYTHING_FOR_EDIT_MATTER_FAILURE,
	CREATE_NEW_MATTER_REQUEST,
	CREATE_NEW_MATTER_SUCCESS,
	CREATE_NEW_MATTER_FAILURE,
	UNMOUNT_NEW_EDIT_MATTER,
	SET_SUBHEADER_TEXT,
	SET_EDIT_MATTER_REDIRECT_LOCATION,
	CLOSE_GLOBAL_EDIT_DIALOG,
	OPEN_GLOBAL_SNACKBAR,
	CLOSE_GLOBAL_SNACKBAR,
} from "./types";
import React from "react";
import { beginBulkDownloadJob } from "./jobProgressActions";

import { setGlobalSearchMatterIDs } from "./globalSearchbarActions";

import store from "../store";

import API from "../utilities/LocalApiProxy";
import { openGlobalDialog } from "./globalEditDialogActions";
import { numberWithCommas } from "../utilities/utilities";

function matterDataHelper(matterData) {
	const newMatterData = { ...matterData };
	newMatterData.matterTags = matterData.matterTags
		? matterData.matterTags.map((tag) => tag.label)
		: [];
	newMatterData.assignedStaff = matterData.assignedStaff
		? matterData.assignedStaff.map(
				//.value is the id of the user.  Should this be the sub?
				(atty) => atty.value
			)
		: [];

	if (matterData.client) {
		if (matterData.client.__isNew__) {
			newMatterData.newClientName = matterData.client.value;
		} else {
			newMatterData.existingClientID = matterData.client.clientID;
		}
	} else {
		newMatterData.existingClientID = null;
	}
	if (matterData.matterType) {
		if (matterData.matterType.__isNew__) {
			newMatterData.newMatterTypeName = matterData.matterType.value;
		} else {
			newMatterData.existingMatterTypeID =
				matterData.matterType.matterTypeID;
		}
	} else {
		newMatterData.existingMatterTypeID = null;
	}
	if (matterData.officeBranch) {
		if (matterData.officeBranch.__isNew__) {
			newMatterData.newOfficeBranchName = matterData.officeBranch.value;
		} else {
			newMatterData.existingOfficeBranchID =
				matterData.officeBranch.officeBranchID;
		}
	} else {
		newMatterData.existingOfficeBranchID = null;
	}
	newMatterData.dateOpened = matterData.dateOpened
		? matterData.dateOpened.toJSON().slice(0, 10)
		: null;
	newMatterData.dateClosed = matterData.dateClosed
		? matterData.dateClosed.toJSON().slice(0, 10)
		: null;
	return newMatterData;
}
export function createNewMatter(matterData) {
	matterData.name = matterData.name.trim();
	const newMatterData = matterDataHelper(matterData);
	return function (dispatch) {
		dispatch({ type: CREATE_NEW_MATTER_REQUEST });
		API.post("/matters/new", {
			body: { newMatterData: newMatterData },
		})
			.then((results) => {
				window.globalHistory.push(
					`/matters/${results.mysql.newMatterID}/docs/`
				);
				dispatch({ type: CREATE_NEW_MATTER_SUCCESS });
			})
			.catch(() => {
				dispatch({ type: CREATE_NEW_MATTER_FAILURE });
			});
	};
}
export function openBulkMatterPermissionsDialog({ allMatters, clientName }) {
	return function (dispatch, getState) {
		function submitBulkMatterPermissionsDialog(state) {
			// submit function
			const matterPermissionsOutputObject =
				state.matterPermissionsOutputObject;
			// matterPermissionsOutputObject example: {
			// 	matterPermissionGlobalSetting: "allUsers" || "specifiedUsers",
			// 	userAllowList:
			// 		this.state.userAllowList && !setToAllUsers
			// 			? this.state.userAllowList.map((u) => ({
			// 					userSub: u.value,
			// 					type: "ALLOW_READ_WRITE",
			// 				}))
			// 			: [],
			// 	groupAllowList:
			// 		this.state.groupAllowList && !setToAllUsers
			// 			? this.state.groupAllowList.map((g) => ({
			// 					groupID: g.value,
			// 					type: "ALLOW_READ_WRITE",
			// 				}))
			// 			: [],
			// 	matterIDsToApplyPermissionsTo: []<matterIDs>,
			// 	ignoreMattersThatAlreadyHavePermissions: boolean,
			// 	confirmed: this.state.confirmBox === "confirm",
			// 	allMatchingMatterIDsWithPermissions: []<matterIDs>,
			// };
			if (matterPermissionsOutputObject) {
				const requestBody = {};
				if (
					matterPermissionsOutputObject.matterPermissionGlobalSetting ===
					"specifiedUsers"
				) {
					requestBody.userAllowList =
						matterPermissionsOutputObject.userAllowList;
					requestBody.groupAllowList =
						matterPermissionsOutputObject.groupAllowList;
					requestBody.onlyDeleteExistingPermissions = false;
				}

				if (
					matterPermissionsOutputObject.matterPermissionGlobalSetting ===
					"allUsers"
				) {
					requestBody.onlyDeleteExistingPermissions = true;
				}

				if (
					matterPermissionsOutputObject.ignoreMattersThatAlreadyHavePermissions
				) {
					requestBody.matterIDs =
						matterPermissionsOutputObject.matterIDsToApplyPermissionsTo.filter(
							(val) =>
								!matterPermissionsOutputObject.allMatchingMatterIDsWithPermissions.includes(
									val
								)
						);
				} else {
					requestBody.matterIDs =
						matterPermissionsOutputObject.matterIDsToApplyPermissionsTo;
				}

				// show a snackbar with a loading spinner
				dispatch({
					type: OPEN_GLOBAL_SNACKBAR,
					payload: {
						variant: "info",
						message: `Updating permissions for ${numberWithCommas(requestBody.matterIDs.length)} matters`,
						loadingSpinnerVisible: true,
					},
				});

				API.post("/bulk/matter/permissions/apply", {
					body: { ...requestBody },
				})
					.then((res) => {
						dispatch({
							type: OPEN_GLOBAL_SNACKBAR,
							payload: {
								variant: "success",
								message: `Permissions updated for ${numberWithCommas(res.mysql.numMattersUpdated)} matters`,
							},
						});
					})
					.catch((err) => {
						console.error(err);
						dispatch({
							type: OPEN_GLOBAL_SNACKBAR,
							payload: {
								variant: "error",
								message:
									"Unable to process this request. Please contact support for assistance.",
							},
						});
					});
			} else {
				console.error("no matterPermissionsOutputObject");
				dispatch({
					type: OPEN_GLOBAL_SNACKBAR,
					payload: {
						variant: "error",
						message:
							"Unable to process this request. Please contact support for assistance.",
					},
				});
			}
			dispatch({
				type: CLOSE_GLOBAL_EDIT_DIALOG,
			});
		}

		function validateBulkMatterPermissionsDialog(state) {
			// validate function
			if (
				state.matterPermissionsOutputObject.confirmed &&
				state.matterPermissionsOutputObject
					.numMatterPermissionsToBeUpdated > 0
			) {
				return { canSubmit: true };
			} else {
				return {
					canSubmit: false,
					disabledHoverTextForSubmitButton: !state
						.matterPermissionsOutputObject.confirmed
						? '"confirm" must be typed into the box above'
						: "No matters to update",
				};
			}
		}

		// when creating this, accessing the filteredCount and filteredMatterIDs in the MattersList component was detrimental to performance, sometimes crashing the app
		// to get around that problem, we will access the data we need from the state here
		const { globalSearchbar } = getState();
		const matterIDsToApplyPermissionsTo = globalSearchbar
			? globalSearchbar.allVisibleMatterIDsOnScreen
			: [];

		const numMattersToApplyPermissionsTo =
			matterIDsToApplyPermissionsTo.length;

		if (numMattersToApplyPermissionsTo > 0) {
			// open global dialog with a loading spinner and title
			openGlobalDialog(
				{
					label: `Bulk Update Matter Permissions`,
					canSubmit: false,
					disabledHoverTextForSubmitButton:
						'"confirm" must be typed into the box above',
					hideTextfield: true,
					type: "BulkMatterPermissions",
					submitButtonLabel: "Apply",
					readOnly: false,
					extraCSSClass: "WideEditDialog",
					bulkMatterPermissionsObject: {
						loading: true,
						matterIDsToApplyPermissionsTo,
						numFilteredMattersString: numberWithCommas(
							numMattersToApplyPermissionsTo
						),

						numAllMattersString: allMatters
							? numberWithCommas(allMatters.length)
							: 0,
					},
				},
				(state) => submitBulkMatterPermissionsDialog(state),
				(state) => validateBulkMatterPermissionsDialog(state),
				() => {
					// cancel function
					dispatch({
						type: CLOSE_GLOBAL_EDIT_DIALOG,
					});
				}
			)(dispatch);

			API.post("/bulk/matter/permissions/init", {
				body: { matterIDs: matterIDsToApplyPermissionsTo },
			})
				.then((res) => {
					// close the loading snackbar
					dispatch({
						type: CLOSE_GLOBAL_SNACKBAR,
					});

					const {
						groupsAndUsers,
						allMatchingMatterIDsWithPermissions,
					} = res.mysql;
					const { groups, groupMembership, users } = groupsAndUsers;
					// update the global dialog with the new data
					openGlobalDialog(
						{
							label: `Bulk Update Matter Permissions`,
							canSubmit: false,
							disabledHoverTextForSubmitButton:
								'"confirm" must be typed into the box above',
							hideTextfield: true,
							type: "BulkMatterPermissions",
							submitButtonLabel: "Apply",
							readOnly: false,
							extraCSSClass: "WideEditDialog",
							bulkMatterPermissionsObject: {
								loading: false,
								matterIDsToApplyPermissionsTo,
								availableGroups: groups.map((p) => ({
									value: p.id,
									label: p.name,
									isFixed: p.type === "FIRM_ADMIN",
								})),
								groups,
								groupMembership,
								availableUsers: users.map((user) => {
									// value and label are required by react-select component
									// the other user properties (type and status)
									// are ultimately used by UsersDropDownSelect to
									// allow showing/hiding of disabled users
									return {
										...user,
										value: user.sub,
										label: user.displayName,
									};
								}),
								users,
								allMatchingMatterIDsWithPermissions,
								numFilteredMattersString: numberWithCommas(
									numMattersToApplyPermissionsTo
								),
								numAllMattersString: allMatters
									? numberWithCommas(allMatters.length)
									: 0,
								hasValidActiveFilter:
									numMattersToApplyPermissionsTo <
									allMatters.length,
								clientName,
							},
						},
						(state) => submitBulkMatterPermissionsDialog(state),
						(state) => validateBulkMatterPermissionsDialog(state),
						() => {
							// cancel function
							dispatch({
								type: CLOSE_GLOBAL_EDIT_DIALOG,
							});
						}
					)(dispatch);
				})
				.catch((err) => {
					console.log(err);
					// if there is an error, close the loading global dialog and open an error snackbar
					dispatch({
						type: CLOSE_GLOBAL_EDIT_DIALOG,
					});
					dispatch({
						type: OPEN_GLOBAL_SNACKBAR,
						payload: {
							variant: "error",
							message:
								"Unable to process this request. Please contact support for assistance.",
						},
					});
				});
		} else {
			console.log("active filter does not include any matters");
			dispatch({
				type: OPEN_GLOBAL_SNACKBAR,
				payload: {
					variant: "error",
					message: "Your filter must include at least one matter",
				},
			});
		}
	};
}
export function getEverythingForEditMatter(matterID) {
	return function (dispatch) {
		var helperPromise = API.get("/matter/editMatterHelper", {
			queryParams: { matterID: matterID },
		});

		dispatch({ type: GET_EVERYTHING_FOR_NEW_EDIT_MATTER_REQUEST });

		helperPromise
			.then((results) => {
				dispatch({
					type: GET_EVERYTHING_FOR_EDIT_MATTER_SUCCESS,
					payload: {
						availableAssignedStaff: results.mysql.Users,
						availableMatterTags: results.mysql.MatterTags,
						availableMatterTypes: results.mysql.MatterTypes,
						availableOfficeBranches: results.mysql.OfficeBranch,
						availableClients: results.mysql.Clients,
						matterDetails: results.mysql.MatterData,
						matterPermissions: results.mysql.MatterPermissions,
						permissionsGroups: results.mysql.PermissionsGroups,
					},
				});
				dispatch({
					type: SET_SUBHEADER_TEXT,
					payload: {
						subheaderText:
							"Editing " + results.mysql.MatterData.name,
					},
				});
			})
			.catch(() => {
				dispatch({
					type: GET_EVERYTHING_FOR_EDIT_MATTER_FAILURE,
				});
				window.globalHistory.push("/");
			});
	};
}
export function unmountNewEditMatter() {
	return function (dispatch) {
		dispatch({
			type: UNMOUNT_NEW_EDIT_MATTER,
		});
	};
}
export function updateMatter(matterID, matterData) {
	const newMatterData = matterDataHelper(matterData);
	return function () {
		API.put("/matter", {
			queryParams: { matterID },
			body: { newMatterData: newMatterData },
		}).then(() => {
			const matterState = store.getState().matter;
			window.globalHistory.push(matterState.editMatterRedirectLocation);
		});
	};
}
export function closeMatter(matterID, reopen) {
	return function (dispatch) {
		API.post("/matter/close", {
			body: { matterID, reopen },
		}).then((response) => {
			console.log("matter closed", response);
			dispatch({
				type: reopen ? "REOPEN_MATTER_SUCCESS" : "CLOSE_MATTER_SUCCESS",
				payload: { closedDate: response.mysql.closedDate },
			});
		});
	};
}
export function getEverythingForNewMatter() {
	return function (dispatch) {
		dispatch({ type: GET_EVERYTHING_FOR_NEW_EDIT_MATTER_REQUEST });
		API.get("/matter/newMatterHelper")
			.then((results) => {
				dispatch({
					type: GET_EVERYTHING_FOR_NEW_MATTER_SUCCESS,
					payload: {
						availableAssignedStaff: results.mysql.Users,
						availableMatterTags: results.mysql.MatterTags,
						availableMatterTypes: results.mysql.MatterTypes,
						availableOfficeBranches: results.mysql.OfficeBranch,
						availableClients: results.mysql.Clients,
						permissionsGroups: results.mysql.PermissionsGroups,
					},
				});
			})
			.catch(() => {
				dispatch({
					type: GET_EVERYTHING_FOR_NEW_MATTER_FAILURE,
				});
			});
	};
}
export function fetchMattersList(filter) {
	return function (dispatch) {
		dispatch({ type: FETCH_MATTERS_LIST_REQUEST });
		const clientID = filter.clientID;
		API.get("/matters", {
			queryParams: { clientID },
		})
			.then((results) => {
				dispatch({
					type: FETCH_MATTERS_LIST_SUCCESS,
					payload: results.mysql,
				});
				if (clientID) {
					setGlobalSearchMatterIDs(
						results.mysql
							.filter((m) => !m.isDeleted)
							.map((x) => x.id)
					)(dispatch);
				}
			})
			.catch(() => {
				dispatch({
					type: FETCH_MATTERS_LIST_FAILURE,
				});
			});
	};
}
export function fetchMatterDetail(matterID) {
	return function (dispatch) {
		dispatch({ type: FETCH_MATTER_DETAIL_REQUEST });
		API.get("/matter", {
			queryParams: { matterID: matterID },
		})
			.then((result) => {
				dispatch({
					type: FETCH_MATTER_DETAIL_SUCCESS,
					payload: {
						matterDetails: result.mysql[0],
					},
				});
				dispatch({
					type: SET_SUBHEADER_TEXT,
					payload: {
						subheaderText: result.mysql[0].name,
						subheaderChips: result.mysql[0].tags.length
							? result.mysql[0].tags.map((tag, index) => {
									return { label: tag.tagName, key: index };
								})
							: [],
					},
				});
			})
			.catch(() => {
				window.globalHistory.push("/");
				dispatch({
					type: FETCH_MATTER_DETAIL_FAILURE,
				});
			});
	};
}
export function showMatterDetails(matterDetails) {
	return function (dispatch) {
		dispatch({
			type: FETCH_MATTER_DETAIL_SUCCESS,
			payload: {
				matterDetails: matterDetails,
			},
		});
		dispatch({
			type: SET_SUBHEADER_TEXT,
			payload: {
				subheaderText: matterDetails.name,
				subheaderChips: matterDetails.tags.length
					? matterDetails.tags.map((tag, index) => {
							return { label: tag.tagName, key: index };
						})
					: [],
			},
		});
	};
}

export function setDeleted(isDeleted, matterID, clientID) {
	return function (dispatch) {
		API.post(
			"/matter/setDeleted",
			{ body: { isDeleted, matterID } },
			{ dontRedirectToHomepage: true }
		)
			.then(() => {
				fetchMattersList({ clientID })(dispatch);
				dispatch({
					type: OPEN_GLOBAL_SNACKBAR,
					payload: {
						variant: "success",
						message: (
							<div>
								{isDeleted ? "Deleted" : "Restored"} matter
								successfully. Click{" "}
								<a
									style={{
										color: "#ffff",
										textDecoration: "underline",
										cursor: "pointer",
									}}
									onClick={() =>
										setDeleted(
											!isDeleted,
											matterID,
											clientID
										)(dispatch)
									}
								>
									Here
								</a>{" "}
								to undo
							</div>
						),
					},
				});
			})
			.catch(() => {
				fetchMattersList({ clientID })(dispatch);
				dispatch({
					type: OPEN_GLOBAL_SNACKBAR,
					payload: {
						variant: "error",
						message: (
							<div>
								Failed to {isDeleted ? "delete" : "restore"}{" "}
								matter. Please try again.
							</div>
						),
					},
				});
			});
	};
}
export function exportMatter(matterID) {
	return function (dispatch) {
		API.post("/matter/export", { body: { matterID } }).then((res) => {
			if (res && res.mysql && res.mysql.jobID) {
				var jobID = res.mysql.jobID;
				beginBulkDownloadJob(jobID, true)(dispatch);
			}
		});
	};
}
export function setEditMatterRedirectLocation(url) {
	return function (dispatch) {
		dispatch({ type: SET_EDIT_MATTER_REDIRECT_LOCATION, payload: { url } });
	};
}
