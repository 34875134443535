import { updateRecentlyViewedMatters } from "./browserLocalstorageActions";
import { SET_SEARCH_BAR_CONTEXT, SET_SEARCH_BAR_MATTER_IDS } from "./types";

export function navigated(newLocation) {
	return function (dispatch, getState) {
		//dispatching after logging out causes an infinite loop with the history.location listener setup in App.js
		if (newLocation) {
			let matterMatch = newLocation.match(/\/matters\/(\d+)/) || [];
			if (matterMatch[1]) {
				// we have a matterID
				updateRecentlyViewedMatters(matterMatch[1])(dispatch, getState);
			}
		}
		if (newLocation !== "/login") {
			dispatch({
				type: SET_SEARCH_BAR_CONTEXT,
				payload: { location: newLocation },
			});
		}
	};
}
export function setGlobalSearchMatterIDs(idsByCategory) {
	return function (dispatch) {
		dispatch({
			type: SET_SEARCH_BAR_MATTER_IDS,
			payload: idsByCategory,
		});
		navigated()(dispatch);
	};
}
