import {} from "../actions/types";

const initialState = {
	loading: false,
};

export default function (state = initialState, action) {
	const { type } = action;
	const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)$/.exec(type);

	if (!matches) return state;

	const [, requestName, requestState] = matches;

	return {
		...state,
		[requestName]: requestState === "REQUEST",
		loading: requestState === "REQUEST",
	};
}
