import React, { Component, useState } from "react";
import AsyncSwitch from "../AsyncSwitch";
import API from "../../utilities/LocalApiProxy";
import { connect } from "react-redux";
import { setSubheaderText } from "../../actions/navigateActions";

const getDocumentStampSettings = () => {
	return API.get("/document/stamping/settings");
};

const getDocIdLine = (stampSettings) => {
	if (stampSettings.documentIdEnabled) {
		return (
			<div>
				<span style={{ fontFamily: "OpenSans-SemiBold" }}>
					DOCUMENT ID:{" "}
				</span>
				123456
			</div>
		);
	}
	return null;
};

const getMatterLine = (stampSettings) => {
	if (stampSettings.matterIdEnabled && stampSettings.matterNameEnabled) {
		return (
			<div>
				<span style={{ fontFamily: "OpenSans-SemiBold" }}>
					MATTER:{" "}
				</span>
				1023, <span>Default Matter Name</span>
			</div>
		);
	} else if (stampSettings.matterIdEnabled) {
		return (
			<div>
				<span style={{ fontFamily: "OpenSans-SemiBold" }}>
					MATTER:{" "}
				</span>
				1023
			</div>
		);
	} else if (stampSettings.matterNameEnabled) {
		return (
			<div>
				<span style={{ fontFamily: "OpenSans-SemiBold" }}>
					MATTER:{" "}
				</span>
				Default Matter Name
			</div>
		);
	}
	return null;
};

const getDocPropsLine = (stampSettings) => {
	let docPropsTitles = [];
	let docPropsValues = [];
	if (stampSettings.documentTypeEnabled) {
		docPropsTitles.push("TYPE:");
		docPropsValues.push("Contract ");
	}
	if (stampSettings.documentStatusEnabled) {
		docPropsTitles.push("STATUS:");
		docPropsValues.push("In Review ");
	}
	if (stampSettings.documentTagsEnabled) {
		docPropsTitles.push("TAGS:");
		docPropsValues.push("Filed With Court, Fully Executed");
	}
	if (docPropsTitles.length) {
		return (
			<div>
				{docPropsTitles.map((title, index) => {
					return (
						<span key={"docProp" + index}>
							<span style={{ fontFamily: "OpenSans-SemiBold" }}>
								{title}{" "}
							</span>
							{docPropsValues[index]}{" "}
						</span>
					);
				})}
			</div>
		);
	}
	return null;
};

const getDocumentStampPreview = (stampSettings) => {
	let line1 = getDocIdLine(stampSettings);
	let line2 = getMatterLine(stampSettings);
	let line3 = getDocPropsLine(stampSettings);

	return (
		<div>
			<h3 style={{ fontSize: 20, marginTop: 50 }}>
				Document Stamp Preview
			</h3>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					marginLeft: 10,
					border: "solid 1px var(--lw-light-blue)",
					padding: 15,
					borderRadius: 4,
				}}
			>
				<div id="docStampLine1">{line1}</div>
				<div id="docStampLine2">{line2}</div>
				<div style={{ display: "flex" }} id="docStampLine3">
					{line3}
				</div>
			</div>
		</div>
	);
};

const updateDocumentStampObject = (key, newValue, stampSettingsObject) => {
	let updateObject = {
		...stampSettingsObject,
		[key]: newValue,
	};
	return API.post("/document/stamping/settings", {
		body: { documentStampSettings: updateObject },
	})
		.then((res) => {
			console.log("update successful", res);
			return Promise.resolve(updateObject);
		})
		.catch((e) => {
			console.log(e);
			return Promise.resolve(stampSettingsObject);
		});
};

const DocumentStampSettings = (props) => {
	const [loading, setLoading] = useState(true);
	const [stampSettingsObject, setStampSettingsObject] = useState({
		documentIdEnabled: true,
	});
	const [requestCountObject, setRequestCountObject] = useState({});
	const [requestInProgress, setRequestInProgress] = useState(false);

	React.useEffect(() => {
		getDocumentStampSettings()
			.then((res) => {
				if (res.mysql) {
					const { stampSettings } = res.mysql;
					setStampSettingsObject(stampSettings);
					let requestCountObject = {};
					Object.keys(stampSettings).forEach((key) => {
						requestCountObject[key] = 0;
					});
					setLoading(false);
					setRequestCountObject(requestCountObject);
				}
			})
			.catch((e) => {
				console.error(
					"error in useEffect for getDocuemntStampSettings",
					e
				);
			});
	}, []);

	const handleSwitchUpdate = ({ key, newStampSettingsObject }) => {
		setStampSettingsObject(newStampSettingsObject);
		let newRequestCountValue = requestCountObject[key]
			? requestCountObject[key] + 1
			: 1;
		let newRequestObject = {
			...requestCountObject,
			[key]: newRequestCountValue,
		};
		setRequestCountObject(newRequestObject);
		setRequestInProgress(false);
	};

	return (
		<div style={{ width: 700, paddingTop: 20, paddingLeft: 15 }}>
			{props.parentComponentIsLoading || loading ? null : (
				<div>
					{props.canEditDocumentStampingSettings ? (
						<></>
					) : (
						<div style={{ marginBottom: 10 }}>
							Only Firm Administrators can edit these settings
						</div>
					)}
					<div>
						<h3 style={{ marginTop: 0 }}>
							Firm Wide Document Stamping Settings
						</h3>
						<h5>{`Document Stamping will be enabled for:`}</h5>
					</div>
					<div
						id="enableDisableDocStampSwitches"
						style={{ marginLeft: 10 }}
					>
						<AsyncSwitch
							label={"Matter Documents"}
							switchValue={stampSettingsObject.matterDocsEnabled}
							disabled={
								!props.canEditDocumentStampingSettings ||
								requestInProgress
							}
							loading={loading}
							updateSetting={(newValue) => {
								setRequestInProgress(true);
								updateDocumentStampObject(
									"matterDocsEnabled",
									newValue,
									stampSettingsObject
								).then((newStampSettingsObject) => {
									handleSwitchUpdate({
										newStampSettingsObject,
										key: "matterDocsEnabled",
									});
								});
							}}
							updateRequestCount={
								requestCountObject.matterDocsEnabled
									? requestCountObject.matterDocsEnabled
									: 0
							}
						/>
						<AsyncSwitch
							label={"Public Firm Documents"}
							switchValue={
								stampSettingsObject.publicFirmDocsEnabled
							}
							disabled={
								!props.canEditDocumentStampingSettings ||
								requestInProgress
							}
							loading={loading}
							updateSetting={(newValue) => {
								setRequestInProgress(true);
								updateDocumentStampObject(
									"publicFirmDocsEnabled",
									newValue,
									stampSettingsObject
								).then((newStampSettingsObject) => {
									handleSwitchUpdate({
										newStampSettingsObject,
										key: "publicFirmDocsEnabled",
									});
								});
							}}
							updateRequestCount={
								requestCountObject.publicFirmDocsEnabled
									? requestCountObject.publicFirmDocsEnabled
									: 0
							}
						/>
						<AsyncSwitch
							label={"Private Firm Documents"}
							switchValue={
								stampSettingsObject.privateFirmDocsEnabled
							}
							disabled={
								!props.canEditDocumentStampingSettings ||
								requestInProgress
							}
							loading={loading}
							updateSetting={(newValue) => {
								setRequestInProgress(true);
								updateDocumentStampObject(
									"privateFirmDocsEnabled",
									newValue,
									stampSettingsObject
								).then((newStampSettingsObject) => {
									handleSwitchUpdate({
										newStampSettingsObject,
										key: "privateFirmDocsEnabled",
									});
								});
							}}
							updateRequestCount={
								requestCountObject.privateFirmDocsEnabled
									? requestCountObject.privateFirmDocsEnabled
									: 0
							}
						/>
					</div>
					<div style={{ marginTop: 50 }}>
						<h3>Incuded in Document Stamp (Word Footer)</h3>
						{/* this indentation is off right now */}
						<h5>{`Select the elements you'd like in each document stamp (document footer):`}</h5>
					</div>
					<div id="docStampOptions" style={{ marginLeft: 10 }}>
						<AsyncSwitch
							label={"Document ID (required)"}
							switchValue={stampSettingsObject.documentIdEnabled}
							disabled={true}
							disabledHoverText={
								"Document ID is always included in the document stamp"
							}
							loading={loading}
							updateSetting={(newValue) => {
								setRequestInProgress(true);
								updateDocumentStampObject(
									"documentIdEnabled",
									newValue,
									stampSettingsObject
								).then((newStampSettingsObject) => {
									handleSwitchUpdate({
										newStampSettingsObject,
										key: "documentIdEnabled",
									});
								});
							}}
							updateRequestCount={
								requestCountObject.documentIdEnabled
									? requestCountObject.documentIdEnabled
									: 0
							}
						/>

						{/* This may be added in the future */}
						{/* <AsyncSwitch
                            label={"Current Version"}
                            switchValue={stampSettingsObject.currentVersionEnabled}
                            disabled={
                                !props.canEditDocumentStampingSettings ||
                                requestInProgress
                            }
                            loading={loading}
                            updateSetting={(newValue) => {
                                setRequestInProgress(true);
                                updateDocumentStampObject(
                                    "currentVersionEnabled",
                                    newValue,
                                    stampSettingsObject
                                ).then((newStampSettingsObject) => {
                                    handleSwitchUpdate({
                                        newStampSettingsObject,
                                        key: "currentVersionEnabled",
                                    });
                                });
                            }}
                            updateRequestCount={
                                requestCountObject.currentVersionEnabled
                                    ? requestCountObject.currentVersionEnabled
                                    : 0
                            }
                        /> */}

						<AsyncSwitch
							label={"Matter ID"}
							switchValue={stampSettingsObject.matterIdEnabled}
							disabled={
								!props.canEditDocumentStampingSettings ||
								requestInProgress
							}
							loading={loading}
							updateSetting={(newValue) => {
								setRequestInProgress(true);
								updateDocumentStampObject(
									"matterIdEnabled",
									newValue,
									stampSettingsObject
								).then((newStampSettingsObject) => {
									handleSwitchUpdate({
										newStampSettingsObject,
										key: "matterIdEnabled",
									});
								});
							}}
							updateRequestCount={
								requestCountObject.matterIdEnabled
									? requestCountObject.matterIdEnabled
									: 0
							}
						/>

						<AsyncSwitch
							label={"Matter Name"}
							switchValue={stampSettingsObject.matterNameEnabled}
							disabled={
								!props.canEditDocumentStampingSettings ||
								requestInProgress
							}
							loading={loading}
							updateSetting={(newValue) => {
								setRequestInProgress(true);
								updateDocumentStampObject(
									"matterNameEnabled",
									newValue,
									stampSettingsObject
								).then((newStampSettingsObject) => {
									handleSwitchUpdate({
										newStampSettingsObject,
										key: "matterNameEnabled",
									});
								});
							}}
							updateRequestCount={
								requestCountObject.matterNameEnabled
									? requestCountObject.matterNameEnabled
									: 0
							}
						/>

						<AsyncSwitch
							label={"Document Type"}
							switchValue={
								stampSettingsObject.documentTypeEnabled
							}
							disabled={
								!props.canEditDocumentStampingSettings ||
								requestInProgress
							}
							loading={loading}
							updateSetting={(newValue) => {
								setRequestInProgress(true);
								updateDocumentStampObject(
									"documentTypeEnabled",
									newValue,
									stampSettingsObject
								).then((newStampSettingsObject) => {
									handleSwitchUpdate({
										newStampSettingsObject,
										key: "documentTypeEnabled",
									});
								});
							}}
							updateRequestCount={
								requestCountObject.documentTypeEnabled
									? requestCountObject.documentTypeEnabled
									: 0
							}
						/>

						<AsyncSwitch
							label={"Document Status"}
							switchValue={
								stampSettingsObject.documentStatusEnabled
							}
							disabled={
								!props.canEditDocumentStampingSettings ||
								requestInProgress
							}
							loading={loading}
							updateSetting={(newValue) => {
								setRequestInProgress(true);
								updateDocumentStampObject(
									"documentStatusEnabled",
									newValue,
									stampSettingsObject
								).then((newStampSettingsObject) => {
									handleSwitchUpdate({
										newStampSettingsObject,
										key: "documentStatusEnabled",
									});
								});
							}}
							updateRequestCount={
								requestCountObject.documentStatusEnabled
									? requestCountObject.documentStatusEnabled
									: 0
							}
						/>

						<AsyncSwitch
							label={"Document Tags"}
							switchValue={
								stampSettingsObject.documentTagsEnabled
							}
							disabled={
								!props.canEditDocumentStampingSettings ||
								requestInProgress
							}
							loading={loading}
							updateSetting={(newValue) => {
								setRequestInProgress(true);
								updateDocumentStampObject(
									"documentTagsEnabled",
									newValue,
									stampSettingsObject
								).then((newStampSettingsObject) => {
									handleSwitchUpdate({
										newStampSettingsObject,
										key: "documentTagsEnabled",
									});
								});
							}}
							updateRequestCount={
								requestCountObject.documentTagsEnabled
									? requestCountObject.documentTagsEnabled
									: 0
							}
						/>
					</div>
					{getDocumentStampPreview(stampSettingsObject)}
				</div>
			)}
		</div>
	);
};

// This is a wrapper component for the DocumentStampSettings component
// It sets the subheader text
// It also passes the canEditDocumentStampingSettings prop to the DocumentStampSettings component
class DocumentStampSettingsWrapper extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.setSubheaderText("Document Stamping Settings");
	}

	render() {
		return (
			<DocumentStampSettings
				canEditDocumentStampingSettings={
					this.props.canEditDocumentStampingSettings
				}
				parentComponentIsLoading={this.props.loading}
			/>
		);
	}
}

const mapStateToProps = (state) => ({
	canEditDocumentStampingSettings: state.user.canEditDocumentStampingSettings,
	loading: state.user.loading,
});
export default connect(mapStateToProps, {
	setSubheaderText,
})(DocumentStampSettingsWrapper);
