import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
	getFavoriteDocs,
	getRecentDocsAndMatters,
} from "../actions/documentActions";
import { getMatterLink, iconMap } from "../utilities/utilities";

const styles = {
	wrapper: {},
	menu: {
		display: "flex",
		position: "absolute",
		top: "100%",
		left: "0",
		zIndex: "1000",
		float: "left",
		padding: "0px 0px",
		margin: "2px 0 0",
		fontSize: "14px",
		textAlign: "left",
		listStyle: "none",
		backgroundColor: "#fff",
		WebkitBackgroundClip: "padding-box",
		backgroundClip: "padding-box",
		border: "1px solid rgba(0,0,0,.15)",
		borderRadius: "4px",
		WebkitBoxShadow: "0 6px 12px rgba(0,0,0,.175)",
		boxShadow: "0 6px 12px rgba(0,0,0,.175)",
		"line-height": "36px",
	},
	column: {
		float: "left",
		"white-space": "nowrap",
		padding: "8px 10px",
		"min-width": "235px",
	},
	list: { padding: "3px 0px", width: 300 },
	listItem: {
		display: "block",
		padding: "7px 5px",
		clear: "both",
		fontWeight: "400",
		lineHeight: "1.42857143",
		color: "#333",
		"&:hover": {
			backgroundColor: "#f5f5f5",
		},
		width: 300,
		maxWidth: 300,
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	columnHeader: {
		width: "100%",
		"border-bottom": "1px solid #DDDDDD",
	},
	listItemLink: {
		"&:hover": {
			color: "#262626",
			textDecoration: "none",
		},
		color: "#333 !important",
	},
};

class MultiColumnDropdownUnstyled extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.openDropdown = this.openDropdown.bind(this);
		this.closeDropdown = this.closeDropdown.bind(this);
		this.container = React.createRef();

		this.state = {
			isDropdownOpen: false,
			dropdownData: this.props.dropdownData,
			loading: false,
		};
	}
	componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
	}
	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}
	openDropdown = () => {
		this.setState(
			{ loading: true, isDropdownOpen: true },
			this.props.loadData
		);
		if (this.props.onOpen && !this.state.isDropdownOpen) {
			this.props.onOpen();
		}
	};
	closeDropdown = () => {
		this.setState(() => ({
			isDropdownOpen: false,
		}));
	};
	handleClickOutside = (event) => {
		if (
			this.container.current &&
			!this.container.current.contains(event.target)
		) {
			this.setState(() => ({
				isDropdownOpen: false,
			}));
		}
	};
	render() {
		return (
			<div
				className={
					this.props.classes.wrapper +
					" DropDownButton RegularDropDown dropdown btn-group"
				}
				ref={this.container}
			>
				<button
					type="button"
					className="mcd-header dropdown-toggle btn btn-default"
					onClick={
						this.state.isDropdownOpen
							? this.closeDropdown
							: this.openDropdown
					}
				>
					{this.props.buttonIcon ? this.props.buttonIcon : null}
					<span>{this.props.dropdownData.title}</span>{" "}
					<span className="caret" />
				</button>
				{this.state.isDropdownOpen && (
					<div
						className={this.props.classes.menu}
						style={{
							width: this.props.customColumnWidth
								? this.props.customColumnWidth
								: "inherit",
						}}
					>
						{this.props.dropdownData && !this.props.loading ? (
							<div style={{ display: "flex" }}>
								{this.props.dropdownData.columns.map(
									(column, colIndex) => {
										return (
											<div
												key={colIndex}
												className={
													this.props.classes.column
												}
												style={{
													width: this.props
														.customColumnWidth
														? this.props
																.customColumnWidth
														: "inherit",
												}}
											>
												{!column.title ? null : (
													<div
														className={
															this.props.classes
																.columnHeader +
															" MainFontBold"
														}
													>
														{column.title}
													</div>
												)}
												{column.list.length ? (
													<div
														className={
															this.props.classes
																.list
														}
														style={{
															width: this.props
																.customColumnWidth
																? this.props
																		.customColumnWidth -
																	20
																: "inherit",
														}}
													>
														{column.list.map(
															(item, index) => {
																return this.props.dropdownData.listItemComponentFn(
																	{
																		item,
																		key:
																			colIndex +
																			"_" +
																			index,
																		onClick:
																			this
																				.closeDropdown,
																	}
																);
															}
														)}
													</div>
												) : (
													<div
														style={{
															color: "#888",
														}}
													>
														(none)
													</div>
												)}
											</div>
										);
									}
								)}
							</div>
						) : (
							<div
								style={{
									paddingTop: 20,
									width: "180px",
									textAlign: "center",
								}}
							>
								<CircularProgress />
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

const FavoriteDocumentsItemUnstyled = ({ item, classes, onClick }) => {
	const { icon, color } = iconMap({
		original: {
			...item,
			type: item.type ? item.type : "File",
		},
	});
	return (
		<Link
			title={item.name ? item.name : item.filename}
			to={item.url}
			className={classes.listItemLink}
			onClick={onClick}
		>
			<div className={classes.listItem}>
				{!item.type ? null : (
					<FontAwesomeIcon
						icon={item.iconName || icon}
						style={{
							color,
							marginRight: 12,
						}}
					/>
				)}
				{item.name ? item.name : item.filename}
			</div>
		</Link>
	);
};
const FavoriteDocumentsItem = withStyles(styles)(FavoriteDocumentsItemUnstyled);

/*
Multiple Column Dropdown Button React Component
Example data to pass to component:
	{			
		dropdownData: {
			title: "Favorite",
			listItemComponentFn:({item,classes,onClick})=><SomeListItemComponent />,

			columns: [
				{
					title: "Documents",
					list: [
						{
							type: "File",
							filename: "WordDoc DOC.docx",
							url: "matters",
						},

						{
							type: "File",
							filename: "worddoc.docx",
							url: "matters",
						},
						{
							type: "File",
							filename: "Spreadsheet.xlsx",
							url: "matters",
						},
						{
							filename: "See All...",
							url:
								"/search?runAfterNavigating=1&q=&m=&type=0&from=&to=&docType=&docStatus=&docTags=&users=&lock=&userOp=&fav=1",
						},
					],
				},
				{
					title: "Folders",
					list: [
						{
							type: "Folder",
							filename: "Folder01",
							url: "matters",
						},
						{
							type: "Folder",
							filename: "Folder02",
							url: "matters",
						},
						{
							type: "Folder",
							filename: "Folder03",
							url: "matters",
						},
					],
				},
			],
		},
		loading: state.document.dropdownDataLoading,
	}
*/
const MultiColumnDropdown = withStyles(styles)(MultiColumnDropdownUnstyled);

const RecentDocsAndMattersDropdown = connect(
	(state) => {
		var recentDocs = [];
		var recentMatters = [];
		state.document.recentDocs.forEach(function (item) {
			recentDocs.push({
				type: "File",
				filename: item.filename,
				url: "/docs/" + item.id,
			});
		});
		if (recentDocs.length) {
			recentDocs.push({
				filename: "See All...",
				url: `/search/${Math.floor(
					Math.random() * 10000
				)}?runAfterNavigating=1&q=&m=&type=0&from=&to=&docType=&docStatus=&docTags=&users=${
					state.user.userID
				}&lock=&userOp=&fav=0&order=0`,
			});
		}
		state.document.recentMatters.forEach(function (item) {
			recentMatters.push({
				type: "Matter",
				name: item.name,
				url: getMatterLink(item.id),
				iconName: "briefcase",
			});
		});

		return {
			dropdownData: {
				title: "Recent",
				columns: [
					{
						title: "Documents",
						list: recentDocs,
						noneText: "No Recent Documents",
					},
					{
						title: "Matters",
						list: recentMatters,
						noneText: "No Recent Matters",
					},
				],
				listItemComponentFn: ({ ...listItemProps }) => {
					return <FavoriteDocumentsItem {...listItemProps} />;
				},
			},
			loading: state.document.recentDocsLoading,
		};
	},
	{
		loadData: getRecentDocsAndMatters,
	}
)(withRouter(MultiColumnDropdown));

const FavoriteDocsDropdown = connect(
	(state) => {
		var favoriteDocs = [];
		var favoriteFolders = [];
		state.document.favoriteDocs.forEach(function (item) {
			favoriteDocs.push({
				type: "File",
				filename: item.filename,
				url: "/docs/" + item.id,
			});
		});
		state.document.favoriteFolders.forEach(function (item) {
			var folderPrefix =
				item.matterID === 0 ? "/firmDocs" : "/matters/" + item.matterID;
			favoriteFolders.push({
				type: "Folder",
				filename: item.filename,
				url: folderPrefix + item.path + item.filename + "/",
			});
		});

		if (favoriteDocs.length) {
			favoriteDocs.push({
				filename: "See All...",
				url: "/search?runAfterNavigating=1&q=&m=&type=0&from=&to=&docType=&docStatus=&docTags=&users=&lock=&userOp=&fav=1",
			});
		}
		return {
			dropdownData: {
				title: "Favorite",
				columns: [
					{
						title: "Documents",
						list: favoriteDocs,
						noneText: "No Favorite Documents",
					},
					{
						title: "Folders",
						list: favoriteFolders,
						noneText: "No Favorite Folders",
					},
				],
				listItemComponentFn: ({ ...listItemProps }) => {
					return <FavoriteDocumentsItem {...listItemProps} />;
				},
			},
			loading: state.document.favoriteDocsLoading,
		};
	},
	{
		loadData: getFavoriteDocs,
	}
)(withRouter(MultiColumnDropdown));

export {
	FavoriteDocsDropdown,
	MultiColumnDropdown,
	RecentDocsAndMattersDropdown,
};
