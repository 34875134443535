import { library } from "@fortawesome/fontawesome-svg-core";

import {
	faBriefcase,
	faCoffee,
	faFolder,
	faFileUpload,
	faCheck,
	faUser,
	faPlusCircle,
	faCog,
	faSearch,
	faClock,
	faHome,
	faQuestionCircle,
	faFax,
	faTimes,
	faColumns,
	faTag,
	faUserTag,
	faEllipsisV,
	faFile,
	faFileExcel,
	faFileWord,
	faFilePdf,
	faFilePowerpoint,
	faFileImage,
	faFileVideo,
	faFileAudio,
	faFileArchive,
	faFileCsv,
	faFileCode,
	faStar,
	faTrash,
	faEnvelope,
	faChevronDown,
	faChevronUp,
	faPencilAlt,
	faGavel,
	faLevelUpAlt,
	faFolderOpen,
	faExternalLinkAlt,
	faCaretLeft,
	faCaretRight,
	faShareSquare,
	faExclamation,
	faDownload,
	faAsterisk,
	faFlask,
	faCode,
	faInfoCircle,
	faKey,
	faUndo,
	faUsers,
	faEye,
	faWindowClose,
	faPenSquare,
	faSync,
	faExclamationTriangle,
	faArrowUp,
	faSave,
	faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import {
	faSquare,
	faCheckSquare,
	faStar as faStarOutline,
	faCompass,
	faBell,
	faPaperPlane,
} from "@fortawesome/free-regular-svg-icons";

import {
	faSparkles,
	faArrowUpRightFromSquare,
	faUserGroupCrown,
} from "@fortawesome/pro-solid-svg-icons";

//could just import all icons, but should check the prod bundle size before/after:

//import { fas } from "@fortawesome/free-solid-svg-icons";
//import { far } from "@fortawesome/free-regular-svg-icons";

function initIcons() {
	//if importing all, dont need to explicitly add every single icon
	//library.add(fas, far);

	library.add(
		faBriefcase,
		faCoffee,
		faFolder,
		faFileUpload,
		faCheck,
		faUser,
		faPlusCircle,
		faCog,
		faSearch,
		faClock,
		faHome,
		faQuestionCircle,
		faFax,
		faTimes,
		faColumns,
		faSquare,
		faCheckSquare,
		faTag,
		faUserTag,
		faEllipsisV,
		faFile,
		faFileExcel,
		faFileWord,
		faFilePdf,
		faFilePowerpoint,
		faFileImage,
		faFileVideo,
		faFileAudio,
		faFileArchive,
		faFileCsv,
		faFileCode,
		faStar,
		faStarOutline,
		faTrash,
		faEnvelope,
		faChevronDown,
		faChevronUp,
		faPencilAlt,
		faGavel,
		faLevelUpAlt,
		faFolderOpen,
		faExternalLinkAlt,
		faCaretLeft,
		faCaretRight,
		faShareSquare,
		faExclamation,
		faDownload,
		faCompass,
		faAsterisk,
		faFlask,
		faCode,
		faInfoCircle,
		faKey,
		faBell,
		faUndo,
		faUsers,
		faEye,
		faWindowClose,
		faPenSquare,
		faPaperPlane,
		faSync,
		faExclamationTriangle,
		faSparkles,
		faArrowUpRightFromSquare,
		faUserGroupCrown,
		faArrowUp,
		faSave,
		faStickyNote
	);
}

export { initIcons };
