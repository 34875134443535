import React, { Component } from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import { setSubheaderText } from "../actions/navigateActions";

const LinkRow = ({ href, text }) => {
	return (
		<div style={{ marginBottom: 10, marginLeft: 0 }}>
			<Link style={{ fontSize: "1.1em" }} to={href}>
				{text}
			</Link>
		</div>
	);
};
class Settings extends Component {
	componentDidMount() {
		this.props.setSubheaderText("LexWorkplace Settings");
	}

	render() {
		return (
			<div style={{ paddingTop: 20, paddingLeft: 15 }}>
				<div>
					<h3 style={{ fontSize: 20 }}>Users & Security</h3>
					<LinkRow
						href="settings/changePassword"
						text="Change Password"
					/>
					<LinkRow
						href="settings/changeUserAttributes"
						text="Change User Attributes"
					/>
					<LinkRow
						href="settings/configureMFA"
						text="Configure Multi-factor Authentication (MFA)"
					/>
				</div>

				{this.props.hasPermissionsAccess ? (
					<>
						<div>
							<h3 style={{ fontSize: 20 }}>
								Permissions & Access
							</h3>
							<LinkRow
								href="permissions/groups/list"
								text="Edit Permission Groups"
							/>
							<LinkRow
								href="/permissions/CREATE_CLIENT_ALLOWED"
								text="Limit Client/Matter Creation"
							/>
						</div>
					</>
				) : null}
				<div>
					<h3 style={{ fontSize: 20 }}>Document Management</h3>
					<LinkRow
						href="/settings/documentStampSettings"
						text="Document Stamping"
					/>
				</div>
				<div>
					<h3 style={{ fontSize: 20 }}>Lists & Tags</h3>

					<LinkRow
						href="settings/manageDocumentTags"
						text="Manage Document Tags"
					/>
					<LinkRow
						href="settings/manageDocumentTypes"
						text="Manage Document Types"
					/>
					<LinkRow
						href="settings/manageDocumentStatuses"
						text="Manage Document Statuses"
					/>
					<LinkRow
						href="settings/manageClientTags"
						text="Manage Client Tags"
					/>
					<LinkRow
						href="settings/manageMatterTags"
						text="Manage Matter Tags"
					/>
					<LinkRow
						href="settings/manageMatterTypes"
						text="Manage Matter Types"
					/>
					<LinkRow
						href="settings/manageOfficeBranch"
						text="Manage Office/Branch List"
					/>
				</div>
				<div>
					<h3 style={{ fontSize: 20 }}>Exchange & Email</h3>
					<LinkRow
						href="settings/updateExchangeUrl"
						text="Update Microsoft Exchange Server URL"
					/>

					<LinkRow
						href="settings/outlookAddinAddress"
						text="Get Outlook Addin Address"
					/>
				</div>
				<div>
					<h3 style={{ fontSize: 20, marginLeft: 0 }}>Downloads</h3>
					<div style={{ marginBottom: 10, marginLeft: 0 }}>
						<a
							style={{ fontSize: "1.1em" }}
							href={this.props.launcherURL}
						>
							LexWorkplace Launcher
						</a>
					</div>
				</div>
				<div>
					<h3 style={{ fontSize: 20 }}>Integrations</h3>

					<LinkRow
						href="settings/integrations"
						text="Manage Integrations"
					/>
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	launcherURL: state.global.launcherURL,
	hasPermissionsAccess: state.user.hasPermissionsAccess,
});
export default connect(mapStateToProps, { setSubheaderText })(Settings);
