import API from "../utilities/LocalApiProxy";

import {
	listDirectory,
	downloadMultiple,
	externalSendMultiple,
} from "./documentActions";

import { openGlobalDialog } from "./globalEditDialogActions";

import {
	beginCopyPasteJob,
	beginCutPasteJob,
	beginDeleteJob,
	beginDuplicationJob,
} from "./jobProgressActions";

import {
	PASTE_SUCCESS,
	SET_CHECKBOX,
	CUT_SUCCESS,
	COPY_SUCCESS,
	DELETE_REQUEST,
	DELETE_SUCCESS,
	DELETE_FAILURE,
	RESTORE_REQUEST,
	RESTORE_SUCCESS,
	RESTORE_FAILURE,
	SET_ALL_CHECKBOXES,
	UNSELECT_ALL,
	OBJECT_AND_FOLDER_DUPLICATE_NAMES_FOUND_SUCCESS,
	CLOSE_GLOBAL_SNACKBAR,
} from "../actions/types";

export function setCheckbox(listName, id, checked, type, row) {
	return function (dispatch) {
		dispatch({
			type: SET_CHECKBOX,
			payload: { listName, id, checked, type, row },
		});
	};
}
export function setAllCheckboxes(listName, config) {
	return function (dispatch) {
		dispatch({
			type: SET_ALL_CHECKBOXES,
			payload: { listName, config },
		});
	};
}
export function selectAll() {
	return function () {};
}
export function unselectAll() {
	return function (dispatch) {
		dispatch({ type: UNSELECT_ALL });
	};
}
export function copy(sourceBasepath, sourceDirectoryID) {
	return function (dispatch) {
		dispatch({
			type: COPY_SUCCESS,
			payload: { sourceBasepath, sourceDirectoryID },
		});
	};
}
export function cut(sourceBasepath, sourceDirectoryID) {
	return function (dispatch) {
		dispatch({
			type: CUT_SUCCESS,
			payload: { sourceBasepath, sourceDirectoryID },
		});
	};
}

export function duplicateDocument({
	matterID,
	destinationDirectoryID,
	destinationDirectoryPath,
	backendRoot, //documents, email, notes
	autoRename,
	newObjectNames,
	newDirectoryNames,
	documentObject,
}) {
	return function (dispatch) {
		const multiselect = {
			cut: false,
			duplicate: true,
			clipboard: {
				Object: { ...documentObject },
				Folder: {},
			},
			sourceBasepath: destinationDirectoryPath, // duplicate to the same directory
		};
		handlePasteAndDuplicate({
			matterID,
			destinationDirectoryID,
			destinationDirectoryPath,
			backendRoot,
			autoRename,
			newObjectNames,
			newDirectoryNames,
			multiselect,
		})(dispatch);
	};
}

export function paste({
	matterID,
	destinationDirectoryID,
	destinationDirectoryPath,
	backendRoot, //documents, email, notes
	autoRename,
	newObjectNames,
	newDirectoryNames,
}) {
	return function (dispatch, getState) {
		const { multiselect } = getState();
		handlePasteAndDuplicate({
			matterID,
			destinationDirectoryID,
			destinationDirectoryPath,
			backendRoot,
			autoRename,
			newObjectNames,
			newDirectoryNames,
			multiselect,
		})(dispatch);
	};
}

function handlePasteAndDuplicate({
	matterID,
	destinationDirectoryID,
	destinationDirectoryPath,
	backendRoot, //documents, email, notes
	autoRename,
	newObjectNames,
	newDirectoryNames,
	multiselect,
}) {
	return function (dispatch, getState) {
		const cameFromCut = multiselect.cut;
		const isDuplicationJob = multiselect.duplicate;
		const sourceBasepath = multiselect.sourceBasepath;
		const items = multiselect.clipboard;

		const objectIDs = Object.keys(items.Object).map(
			(key) => items.Object[key].id
		);
		const directoryIDs = Object.keys(items.Folder).map(
			(key) => items.Folder[key].id
		);

		var endpoint;

		if (backendRoot === "/document") {
			endpoint = cameFromCut ? "/document/move" : "/document/copy";
		} else if (backendRoot === "/email") {
			endpoint = cameFromCut ? "/email/move" : "/email/copy";
		} else if (backendRoot === "/notes") {
			endpoint = cameFromCut ? "/notes/move" : "/notes/copy";
		} else {
			console.error(
				"invalid backendRoot passed to multiselectActions::paste"
			);
			return 0;
		}

		const successCallback = () => {
			var itemType = getSnackbarSuccessNoun(items);
			listDirectory(matterID, destinationDirectoryPath)(dispatch);
			dispatch({
				type: PASTE_SUCCESS,
				payload: {
					sourceBasepath,
					itemType,
					verb: cameFromCut
						? "moved"
						: isDuplicationJob
							? "duplicated"
							: "copied",
				},
			});
		};
		const failureCallback = () => {
			getSnackbarSuccessNoun(items);
			listDirectory(matterID, destinationDirectoryPath)(dispatch);
		};
		dispatch({ type: "PASTE_REQUEST" });
		if (isDuplicationJob) {
			beginDuplicationJob(0, successCallback)(dispatch);
		} else if (!cameFromCut && backendRoot !== "/notes") {
			//just open the progress snackbar
			beginCopyPasteJob(0, successCallback)(dispatch);
		} else if (cameFromCut && backendRoot !== "/notes") {
			beginCutPasteJob(0, successCallback)(dispatch);
		}
		API.post(endpoint, {
			body: {
				matterID,
				destinationDirectoryID,
				destinationDirectoryPath,
				objectIDs,
				directoryIDs,
				sourceBasepath,
				autoRename,
				newObjectNames,
				newDirectoryNames,
			},
		})
			.then((res) => {
				if (backendRoot !== "/notes") {
					//a copy or cut that is just individual files or emails (ie no folders)
					//will not return with a jobID, and the page should refresh immediately
					if (res && res.mysql && res.mysql.jobID) {
						var jobID = res.mysql.jobID;

						if (cameFromCut) {
							beginCutPasteJob(
								jobID,
								successCallback,
								failureCallback
							)(dispatch);
						} else {
							beginCopyPasteJob(
								jobID,
								successCallback,
								failureCallback
							)(dispatch);
						}
					} else {
						successCallback();
					}
				} else {
					successCallback();
				}
				/*var itemType = getSnackbarSuccessNoun(items);
				listDirectory(matterID, destinationDirectoryPath)(dispatch);

				dispatch({
					type: PASTE_SUCCESS,
					payload: {
						sourceBasepath,
						itemType,
						verb: cameFromCut ? "moved" : "copied",
					},
				});*/
			})
			.catch(({ lxwErrorCode, lxwErrorData }) => {
				if (lxwErrorCode === 6500) {
					dispatch({
						type: OBJECT_AND_FOLDER_DUPLICATE_NAMES_FOUND_SUCCESS,
					});
					const { numFolders, numFiles } = lxwErrorData;
					openGlobalDialog(
						{
							label: `${
								numFolders + numFiles
							} Duplicate Items Found`,
							mainText: `There are already ${numFolders} folders and ${numFiles} items in this directory with the same name (they might be deleted)`,
							canSubmit: true,
							hideTextfield: true,
							submitButtonLabel: "Rename and Paste",
						},
						() =>
							//call again with autoRename true
							handlePasteAndDuplicate({
								matterID,
								destinationDirectoryID,
								destinationDirectoryPath,
								backendRoot,
								autoRename: true,
								newObjectNames,
								newDirectoryNames,
								multiselect,
							})(dispatch),
						() => ({ canSubmit: true }),
						() => {
							dispatch({ type: CLOSE_GLOBAL_SNACKBAR });
						}
					)(dispatch);
				} else {
					failureCallback();
				}
			});
	};
}
function getSnackbarSuccessNoun(selectedItems) {
	// wanted the display name to change from File to Document, but File is the key in the object and it is referenced in the backend
	// changing everything to Document seemed like it could cause issues
	// var IDs = { Folder: [], File: [], Email: [], Note: [] };
	const objectDisplayNameMap = {
		File: "Document",
		Email: "Email",
		Note: "Note",
		Folder: "Folder",
	};
	var itemType = "Object";
	var numObjects = Object.keys(selectedItems.Object).length;
	var numFolders = Object.keys(selectedItems.Folder).length;
	if (numObjects === 0) {
		itemType = "Folder" + (numFolders > 1 ? "s" : "");
	} else {
		if (numFolders === 0) {
			const objectKey =
				selectedItems.Object[Object.keys(selectedItems.Object)[0]].type;
			itemType =
				objectDisplayNameMap[objectKey] + (numObjects > 1 ? "s" : "");
		} else {
			itemType = "Objects";
		}
	}
	return itemType;
}
export function deleteMultiselect(selectedItems, matterID, sourceBasepath) {
	return function (dispatch) {
		deleteRestoreItems(
			selectedItems,
			matterID,
			sourceBasepath,
			true
		)(dispatch);
	};
}
export function restoreMultiselect(selectedItems, matterID, sourceBasepath) {
	return function (dispatch) {
		deleteRestoreItems(
			selectedItems,
			matterID,
			sourceBasepath,
			false
		)(dispatch);
	};
}
export function externalSendAllMultiselect(
	selected,
	parentDirectoryPath,
	matterID,
	recipients,
	message
) {
	console.log(selected);
	console.log("@@@downloadAllMultiselect");
	console.log(selected);
	console.log(parentDirectoryPath);
	console.log(matterID);
	return function (dispatch) {
		var selectedItems = {
			Folder: Object.keys(selected.Folder),
			Object: Object.keys(selected.Object),
		};
		console.log("@@@externalSendAllMultiselect>dispatch");
		console.log(matterID);
		externalSendMultiple(
			selectedItems,
			parentDirectoryPath,
			matterID,
			recipients,
			message
		)(dispatch);
	};
}

export function downloadAllMultiselect(
	selected,
	documentOrEmail,
	parentDirectoryPath,
	matterID
) {
	return function (dispatch) {
		var selectedItems = {
			Folder: Object.keys(selected.Folder),
			Object: Object.keys(selected.Object),
		};
		downloadMultiple(
			selectedItems,
			documentOrEmail,
			parentDirectoryPath,
			matterID
		)(dispatch);
	};
}

function deleteRestoreItems(
	selectedItems,
	matterID,
	sourceBasepath,
	deleteIfTrue
) {
	return function (dispatch) {
		var IDs = { Folder: [], File: [], Email: [], Note: [] };
		Object.keys(selectedItems.Object).forEach((key) => {
			IDs[selectedItems.Object[key].type].push(
				selectedItems.Object[key].id
			);
		});
		Object.keys(selectedItems.Folder).forEach((key) => {
			IDs[selectedItems.Folder[key].type].push(
				selectedItems.Folder[key].id
			);
		});
		var itemType = getSnackbarSuccessNoun(selectedItems);

		function success() {
			dispatch({
				type: deleteIfTrue ? DELETE_SUCCESS : RESTORE_SUCCESS,
				payload: {
					sourceBasepath,
					itemType,
					undoFn: () => {
						deleteRestoreItems(
							selectedItems,
							matterID,
							sourceBasepath,
							!deleteIfTrue
						)(dispatch);
					},
				},
			});
			listDirectory(matterID, sourceBasepath)(dispatch);
		}
		function failure() {
			dispatch({
				type: deleteIfTrue ? DELETE_FAILURE : RESTORE_FAILURE,
			});
		}
		dispatch({ type: deleteIfTrue ? DELETE_REQUEST : RESTORE_REQUEST });
		API.post(deleteIfTrue ? "/multi/delete" : "/multi/restore", {
			body: { IDs, objectType: determineObjectType(sourceBasepath) },
		}).then((res) => {
			if (res.mysql && res.mysql.jobID) {
				beginDeleteJob(
					res.mysql.jobID,
					success,
					failure,
					deleteIfTrue
				)(dispatch);
			} else {
				success();
			}
		});
	};
}
function determineObjectType(sourceBasepath) {
	if (sourceBasepath.indexOf("docs/") === 0) {
		return "Documents";
	} else if (sourceBasepath.indexOf("public/") === 0) {
		return "Documents";
	} else if (sourceBasepath.indexOf("private/") === 0) {
		return "Documents";
	} else if (sourceBasepath.indexOf("email/") === 0) {
		return "Emails";
	} else if (sourceBasepath.indexOf("notes/") === 0) {
		return "Notes";
	} else {
		return "Unknown";
	}
}
