import { SET_LXW_LOCALSTORAGE_VALUE } from "./types";

function toggleValue(key) {
	return function (dispatch, getState) {
		const { browserLocalstorage } = getState();
		const newValue = !browserLocalstorage[key];
		setLocalstorageValue(key, newValue)(dispatch);
	};
}

export function setLocalstorageValue(key, value) {
	return function (dispatch) {
		dispatch({ type: SET_LXW_LOCALSTORAGE_VALUE, payload: { key, value } });
	};
}
export function toggleSidenav() {
	return toggleValue("sidenavExpanded");
}
export function toggleMatterInfo() {
	return toggleValue("matterInfoHidden");
}
export function updateWhatsNewDropdownLastHashViewed(currentWhatsNewItemsHash) {
	return setLocalstorageValue(
		"whatsNewDropdownLastHashViewed",
		currentWhatsNewItemsHash
	);
}
export function updateInlineCompareUrl(inlineCompareUrl) {
	return setLocalstorageValue("inlineCompareUrl", inlineCompareUrl);
}
export function toggleDropdownSelectShowAll(dropdownKey) {
	return function (dispatch, getState) {
		const { browserLocalstorage } = getState();

		let newState = { ...browserLocalstorage.dropdownSelectShowAll };
		const currentVal = newState[dropdownKey];
		newState[dropdownKey] = !currentVal;

		return setLocalstorageValue(
			"dropdownSelectShowAll",
			newState
		)(dispatch);
	};
}

export function updateRecentlyViewedMatters(matterId) {
	return function (dispatch, getState) {
		const { browserLocalstorage } = getState();
		if (!matterId) {
			return;
		}
		if (!browserLocalstorage.recentlyViewedMatters) {
			return setLocalstorageValue("recentlyViewedMatters", [matterId])(
				dispatch
			);
		}
		if (browserLocalstorage.recentlyViewedMatters.indexOf(matterId) === 0) {
			return;
		}
		if (browserLocalstorage.recentlyViewedMatters.includes(matterId)) {
			// if the matter is already in the list, move it to the front
			const newRecentlyViewedMatters = [
				matterId,
				...browserLocalstorage.recentlyViewedMatters.filter(
					(id) => id !== matterId
				),
			];
			return setLocalstorageValue(
				"recentlyViewedMatters",
				newRecentlyViewedMatters
			)(dispatch);
		}
		const newRecentlyViewedMatters = [
			matterId,
			...browserLocalstorage.recentlyViewedMatters,
		].slice(0, 20);
		return setLocalstorageValue(
			"recentlyViewedMatters",
			newRecentlyViewedMatters
		)(dispatch);
	};
}

export function getRecentlyViewMatters() {
	return function (dispatch, getState) {
		const { browserLocalstorage } = getState();
		return browserLocalstorage.recentlyViewedMatters || [];
	};
}
