import {
	LIST_PREFIX_REQUEST,
	LIST_PREFIX_SUCCESS,
	LIST_VERSIONS_SUCCESS,
	DOCUMENT_DETAILS_SUCCESS,
	DOCUMENT_DETAILS_FAILURE,
	DOCUMENT_DETAILS_REQUEST,
	MULTIPLE_FILE_UPLOAD_REQUEST,
	MULTIPLE_FILE_UPLOAD_SUCCESS,
	FETCH_DOCUMENT_TAGS_SUCCESS,
	FETCH_DOCUMENT_TYPES_SUCCESS,
	FETCH_DOCUMENT_STATUSES_SUCCESS,
	UNMOUNT_DOCUMENT_DETAILS,
	FETCH_RECENT_DOCS_REQUEST_NOGLOBAL,
	FETCH_RECENT_DOCS_SUCCESS_NOGLOBAL,
	FETCH_FAVORITE_DOCS_REQUEST_NOGLOBAL,
	FETCH_FAVORITE_DOCS_SUCCESS_NOGLOBAL,
	EMAIL_NOTE_UPDATED,
	DOCUMENT_PREVIEW_CONTENT_SUCCESS,
	DOCUMENT_PREVIEW_CONTENT_FAILURE,
	FETCH_DOCUMENT_PREVIEW_CONTENT,
	FILE_UPLOAD_FAILURES,
	RESET_FILE_UPLOAD_FAILURES,
} from "../actions/types";

//
const initialState = {
	documentList: [],
	recentDocs: [],
	recentMatters: [],
	favoriteDocs: [],
	favoriteFolders: [],
	uploadedFiles: [],
	documentDetails: {},
	availableDocumentTags: [],
	availableDocumentTypes: [],
	availableDocumentStatuses: [],
	documentVersions: [],
	bulkUploadFilesFailed: [],
	originalPrefix: "",
	prefix: "",
	currentRoot: "docs/",
	message: "",
	directoryID: null,
	loading: true,
	recentDocsLoading: false,
	favoriteDocsLoading: false,
	documentPropertiesLoading: true,
	documentPreviewLoading: false,
	documentStampingInformation: {},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case LIST_PREFIX_REQUEST:
			return {
				...state,
				loading: true,
			};
		case LIST_PREFIX_SUCCESS:
			return {
				...state,
				documentList: action.payload.documents,
				originalPrefix: action.payload.originalPrefix,
				prefix: action.payload.prefix,
				currentRoot: action.payload.matterRoot + "/",
				directoryID: action.payload.directoryID,
				loading: false,
			};
		case DOCUMENT_DETAILS_REQUEST:
			return {
				...state,
				documentPropertiesLoading: true,
			};
		case DOCUMENT_DETAILS_SUCCESS:
			return {
				...state,
				documentDetails: action.payload.documentDetails,
				availableDocumentTypes: action.payload.availableDocumentTypes,
				availableDocumentStatuses:
					action.payload.availableDocumentStatuses,
				availableDocumentTags: action.payload.availableDocumentTags,
				documentVersions: action.payload.documentVersions,
				documentFileNamesInSameDirectory:
					action.payload.documentFileNamesInSameDirectory,
				documentPropertiesLoading: false,
				documentStampingInformation:
					action.payload.documentStampingInformation,
			};
		case DOCUMENT_DETAILS_FAILURE:
			return {
				...state,
			};
		case LIST_VERSIONS_SUCCESS:
			return {
				...state,
				versionHistory: action.payload.versions.concat(
					action.payload.deleteMarkers
				),
				loading: false,
			};
		case FILE_UPLOAD_FAILURES:
			return {
				...state,
				bulkUploadFilesFailed: [
					...state.bulkUploadFilesFailed,
					...action.payload.bulkUploadFilesFailed,
				],
			};
		case RESET_FILE_UPLOAD_FAILURES:
			return {
				...state,
				bulkUploadFilesFailed: [],
			};
		case MULTIPLE_FILE_UPLOAD_REQUEST:
			return {
				...state,
			};
		case MULTIPLE_FILE_UPLOAD_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case FETCH_DOCUMENT_TAGS_SUCCESS:
			return {
				...state,
				availableDocumentTags: action.payload.tags,
			};
		case FETCH_DOCUMENT_TYPES_SUCCESS:
			return {
				...state,
				availableDocumentTypes: action.payload.tags,
			};
		case FETCH_DOCUMENT_STATUSES_SUCCESS:
			return {
				...state,
				availableDocumentStatuses: action.payload.tags,
			};
		case UNMOUNT_DOCUMENT_DETAILS:
			return {
				...state,
				documentPropertiesLoading: true,
				documentDetails: {},
			};
		case FETCH_RECENT_DOCS_REQUEST_NOGLOBAL:
			return {
				...state,
				recentDocsLoading: true,
			};
		case FETCH_RECENT_DOCS_SUCCESS_NOGLOBAL:
			var deDuplicated = {};
			action.payload.recentlyModifiedVersions
				.concat(action.payload.recentlyUploadedDocuments)
				.forEach((x) => {
					if (deDuplicated[x.id]) {
						if (x.sortDate > deDuplicated[x.id].sortDate) {
							deDuplicated[x.id] = x;
						}
					} else {
						deDuplicated[x.id] = x;
					}
				});
			return {
				...state,
				recentDocs: Object.keys(deDuplicated)
					.map((docID) => {
						var me = deDuplicated[docID];
						return me;
					})
					.sort((a, b) => {
						return a.sortDate > b.sortDate ? -1 : 1;
					}),
				recentMatters: action.payload.recentlyAccessedMatters,
				recentDocsLoading: false,
			};
		case FETCH_FAVORITE_DOCS_REQUEST_NOGLOBAL:
			return {
				...state,
				favoriteDocsLoading: true,
			};
		case FETCH_FAVORITE_DOCS_SUCCESS_NOGLOBAL:
			return {
				...state,
				favoriteDocs: action.payload.favoriteDocuments,
				favoriteFolders: action.payload.favoriteFolders,
				favoriteDocsLoading: false,
			};
		case EMAIL_NOTE_UPDATED:
			return {
				...state,
				documentList: state.documentList.map((e) => {
					if (action.payload.id === e.id) {
						return { ...e, notes: action.payload.value };
					} else {
						return { ...e };
					}
				}),
			};
		case DOCUMENT_PREVIEW_CONTENT_SUCCESS:
			return {
				...state,
				documentPreview: action.payload,
				documentPreviewLoading: false,
			};
		case DOCUMENT_PREVIEW_CONTENT_FAILURE:
			return {
				...state,
				documentPreview: null,
				documentPreviewLoading: false,
			};
		case FETCH_DOCUMENT_PREVIEW_CONTENT:
			return {
				...state,
				documentPreviewLoading: true,
			};
		default:
			return state;
	}
}
