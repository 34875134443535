import {
	CLOSE_GLOBAL_SNACKBAR,
	OPEN_GLOBAL_SNACKBAR,
	MULTIPLE_FILE_UPLOAD_SUCCESS,
	MULTIPLE_FILE_UPLOAD_FAILURE,
	BULK_FILE_UPLOAD_FAILURE,
	DELETE_SUCCESS,
	RESTORE_SUCCESS,
	PASTE_SUCCESS,
	PASTE_FAILURE,
	UPLOAD_NEW_VERSION_SUCCESS,
	SET_DOCUMENT_LOCK_SUCCESS,
	CLOSE_MATTER_SUCCESS,
	REOPEN_MATTER_SUCCESS,
	REINDEX_SUCCESS,
	REINDEX_FAILURE,
	BULK_DOWNLOAD_FAILURE,
	BULK_DOWNLOAD_SUCCESS,
	FOLDER_NAME_CONFLICT,
	BULK_EXTERNAL_SEND_FAILURE,
	BULK_EXTERNAL_SEND_SUCCESS,
	UPDATE_PERMISSION_FEATURE_DETAILS_REQUEST,
	UPDATE_PERMISSION_FEATURE_DETAILS_SUCCESS,
	UPDATE_PERMISSION_FEATURE_DETAILS_ERROR,
	CONVERT_TO_PDF_SUCCESS,
	CONVERT_TO_PDF_FAILURE,
	UPLOAD_NEW_VERSION_FAILURE,
	REQUEST_OCR_SUCCESS,
	REQUEST_OCR_FAILURE,
	UPDATE_OCR_SNACKBAR_MESSAGE,
	AUDIT_SEARCH_SUCCESS,
	AUDIT_SEARCH_FAILURE,
	INDEX_MISSING_SEARCH_ITEMS_SUCCESS,
	INDEX_MISSING_SEARCH_ITEMS_FAILURE,
	OPEN_PERSISTENT_ERROR_SNACKBAR,
	OPEN_PERSISTENT_SNACKBAR,
} from "../actions/types";
import React from "react";

import { shortDateFormat } from "../utilities/utilities";

const initialState = {
	open: false,
	message: "",
	variant: "",
	autoHideDuration: 6000,
	loadingSpinnerVisible: false,
	allowClose: true,
	disableWindowBlurListener: false,
};

const snackbarStateHolder = {
	// [snackbarKey] = { $stateForSnackbar }
};

const allowedVariants = ["error", "warning", "info", "success"];
// when using undefined as a key in an object, it will be converted to "undefined" string
const allowedSnackbarKeys = [undefined, "undefined", "documentCompare"];
export default function (state = snackbarStateHolder, action) {
	const SNACKBAR_KEY = action?.payload?.snackbarKey;
	if (!allowedSnackbarKeys.includes(SNACKBAR_KEY)) {
		console.error("Invalid snackbarKey: ", SNACKBAR_KEY);
		// please add another nth child class in snackbar.css if adding another snackbarKey
		// otherwise the snackbars will start stacking on each other
		return state;
	}
	switch (action.type) {
		case MULTIPLE_FILE_UPLOAD_SUCCESS:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					message: `Successfully uploaded ${action.payload.num} ${
						action.payload.objectType || "File"
					}${action.payload.num > 1 ? "s" : ""}`,
					variant: "success",
				},
			};
		case MULTIPLE_FILE_UPLOAD_FAILURE:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					message: `There was a problem with the upload.  The ${
						action.payload ? action.payload.num : ""
					} file(s) were not successfully uploaded`,
					variant: "error",
				},
			};
		case BULK_FILE_UPLOAD_FAILURE:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					message:
						action.payload && action.payload.message
							? action.payload.message
							: `There was a problem with the upload.  The ${
									action.payload ? action.payload.num : ""
								} file(s) were not successfully uploaded`,
					variant: "error",
					autoHideDuration:
						action.payload &&
						action.payload.autoHideDuration !== undefined
							? action.payload.autoHideDuration
							: initialState.autoHideDuration,
				},
			};
		case CONVERT_TO_PDF_SUCCESS:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					message: action.payload.message,
					variant: "success",
					loadingSpinnerVisible: false,
					allowClose: true,
					autoHideDuration:
						action.payload &&
						action.payload.autoHideDuration !== undefined
							? action.payload.autoHideDuration
							: initialState.autoHideDuration,
				},
			};
		case REQUEST_OCR_SUCCESS:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					message: action.payload.message,
					variant: "success",
					loadingSpinnerVisible: false,
					allowClose: true,
					autoHideDuration:
						action.payload &&
						action.payload.autoHideDuration !== undefined
							? action.payload.autoHideDuration
							: initialState.autoHideDuration,
				},
			};
		case REQUEST_OCR_FAILURE:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					variant: "error",
					message: action.payload.message,
					loadingSpinnerVisible: false,
					allowClose: true,
				},
			};
		case DELETE_SUCCESS:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					message: (
						<div>
							{action.payload.itemType} deleted. Click{" "}
							<a
								style={{
									color: "#ffff",
									textDecoration: "underline",
									cursor: "pointer",
								}}
								onClick={() => action?.payload?.undoFn()}
							>
								here
							</a>{" "}
							to undo.
						</div>
					),
					variant: "success",
				},
			};
		case RESTORE_SUCCESS:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					message: (
						<div>
							{action.payload.itemType} restored. Click{" "}
							<a
								style={{
									color: "#ffff",
									textDecoration: "underline",
									cursor: "pointer",
								}}
								onClick={() => action?.payload?.undoFn()}
							>
								here
							</a>{" "}
							to undo.
						</div>
					),
					variant: "success",
				},
			};
		case PASTE_SUCCESS:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					message: `${action.payload.itemType} ${action.payload.verb}`,
					variant: "success",
					autoHideDuration: 6000,
					loadingSpinnerVisible: false,
					allowClose: true,
				},
			};
		case PASTE_FAILURE:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					message:
						action.payload.message ||
						"There was a problem with pasting",
					variant: "error",
					autoHideDuration: 6000,
					loadingSpinnerVisible: false,
					allowClose: true,
				},
			};
		case REINDEX_SUCCESS:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					message:
						"Reindex job created.  Click refresh button to see progress",
					variant: "success",
					autoHideDuration: 6000,
					loadingSpinnerVisible: false,
					allowClose: true,
				},
			};
		case REINDEX_FAILURE:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					message: "Reindex job failed",
					variant: "error",
					autoHideDuration: 6000,
					loadingSpinnerVisible: false,
					allowClose: true,
				},
			};
		case UPLOAD_NEW_VERSION_SUCCESS:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					message: "New Version Uploaded",
					variant: "success",
				},
			};
		case UPLOAD_NEW_VERSION_FAILURE:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					message: `${
						action && action.payload && action.payload.message
							? action.payload.message
							: "New version could not be uploaded"
					}`,
					variant: "error",
				},
			};
		case SET_DOCUMENT_LOCK_SUCCESS:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					message: `Document Checked ${
						action.payload.lockStatus ? "Out" : "In"
					}`,
					variant: "success",
				},
			};
		case CLOSE_MATTER_SUCCESS:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					message: `Matter closed on ${shortDateFormat(
						action.payload.closedDate
					)}`,
					variant: "success",
				},
			};
		case REOPEN_MATTER_SUCCESS:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					message: "Matter Reopened",
					variant: "success",
				},
			};
		case CLOSE_GLOBAL_SNACKBAR:
			// eslint-disable-next-line no-case-declarations
			const next = { ...state };
			delete next[SNACKBAR_KEY];
			return next;
		case OPEN_GLOBAL_SNACKBAR:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					message: action.payload.message,
					variant: allowedVariants.includes(action.payload.variant)
						? action.payload.variant
						: "info",
					autoHideDuration: action.payload.autoHideDuration || 6000,
					loadingSpinnerVisible:
						action.payload.loadingSpinnerVisible || false,
					allowClose: false,
					disableWindowBlurListener:
						action.payload.disableWindowBlurListener || false,
				},
			};
		case "OPEN_LOADING_SNACKBAR":
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					autoHideDuration: null,
					variant: "info",
					message: action.payload.message || "Calculating...",
					loadingSpinnerVisible: true,
					allowClose: false,
				},
			};
		case "UPDATE_SNACKBAR_MESSAGE":
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					message: action.payload.message,
				},
			};
		case UPDATE_OCR_SNACKBAR_MESSAGE:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					variant: allowedVariants.includes(action.payload.variant)
						? action.payload.variant
						: "info",
					loadingSpinnerVisible: false,
					allowClose: true,
					message: action.payload.message,
				},
			};
		case BULK_DOWNLOAD_FAILURE:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					variant: "error",
					message: action.payload.message,
					loadingSpinnerVisible: false,
					allowClose: true,
				},
			};
		case BULK_DOWNLOAD_SUCCESS:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					variant: "success",
					message: action.payload.message,
					loadingSpinnerVisible: false,
					allowClose: true,
				},
			};
		case BULK_EXTERNAL_SEND_FAILURE:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					variant: "error",
					message: action.payload.message,
					loadingSpinnerVisible: false,
					allowClose: true,
				},
			};
		case BULK_EXTERNAL_SEND_SUCCESS:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					variant: "success",
					message: action.payload.message,
					loadingSpinnerVisible: false,
					allowClose: true,
				},
			};
		case FOLDER_NAME_CONFLICT:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					variant: "error",
					message:
						"There is already a " +
						(action.payload.length && action.payload[0].isDeleted
							? "deleted "
							: "") +
						"folder with that name",
					loadingSpinnerVisible: false,
					allowClose: true,
				},
			};
		case UPDATE_PERMISSION_FEATURE_DETAILS_REQUEST:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					variant: "info",
					message: "Updating Permissions...",
					loadingSpinnerVisible: true,
					allowClose: false,
				},
			};
		case UPDATE_PERMISSION_FEATURE_DETAILS_SUCCESS:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					variant: "success",
					message: "Permissions Successfully Updated",
					loadingSpinnerVisible: false,
					allowClose: true,
				},
			};
		case UPDATE_PERMISSION_FEATURE_DETAILS_ERROR:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					variant: "error",
					message: "Unable to Update Permissions",
					loadingSpinnerVisible: false,
					allowClose: true,
				},
			};
		case CONVERT_TO_PDF_FAILURE:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					variant: "error",
					message: action.payload.message,
					loadingSpinnerVisible: false,
					allowClose: true,
				},
			};
		case AUDIT_SEARCH_SUCCESS:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					message: action.payload.message,
					variant: "success",
					loadingSpinnerVisible: false,
					allowClose: true,
				},
			};
		case AUDIT_SEARCH_FAILURE:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					variant: "error",
					message: action.payload.message,
					loadingSpinnerVisible: false,
					allowClose: true,
				},
			};
		case INDEX_MISSING_SEARCH_ITEMS_SUCCESS:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					message: action.payload.message,
					variant: "success",
					loadingSpinnerVisible: false,
					allowClose: true,
				},
			};
		case INDEX_MISSING_SEARCH_ITEMS_FAILURE:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					variant: "error",
					message: action.payload.message,
					loadingSpinnerVisible: false,
					allowClose: true,
				},
			};
		case OPEN_PERSISTENT_ERROR_SNACKBAR:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					variant: "error",
					allowClose: true,
					autoHideDuration: null,
					message:
						action.payload.message || "An unknown error occured",
				},
			};
		case OPEN_PERSISTENT_SNACKBAR:
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					variant: action.payload.variant,
					allowClose: true,
					autoHideDuration: null,
					message: action.payload.message,
					loadingSpinnerVisible: false,
				},
			};
		case "OPEN_PERSISTENT_SNACKBAR_NOT_CLOSEABLE":
			return {
				...state,
				[SNACKBAR_KEY]: {
					...initialState,
					...state[SNACKBAR_KEY],
					open: true,
					variant: action.payload.variant,
					allowClose: false,
					autoHideDuration: null,
					message: action.payload.message,
					loadingSpinnerVisible: false,
				},
			};

		default:
			return state;
	}
}
